import axios from "axios";
import { BACKEND_URL } from "../utils/constant";
import PasswordInput from "./PasswordInput";
import { useAuth } from "../context/AuthProvider";

const { useState } = require("react");
const { useForm } = require("../hooks/useForm");
const { useFormatMessage } = require("../hooks/useFormatMessage");


export default function MyResetPopup({userId}) {
    const formatMessage = useFormatMessage();
    const {setPopup} = useAuth()
  
    const {handleInputChange, formData, submitForm, setError, error} = useForm();
  
    const onSubmit = e => {
      e.preventDefault();
      console.log(formData, formData.password != formData.confirm_password)
      if (formData.password != formData.confirm_password)
      {
        setError('Password are not the same!')
      }
      else 
      {

        const pCheckPassword = {
          password: formData.current_password
        }
        axios.request({
          method: 'POST',
          url: `${BACKEND_URL}/auth/checkPassword`,
          data: pCheckPassword,
          withCredentials: true
        }).then(response => {
          if (response.data.correct)
          {
            const data = {
              password: formData.password
            }
            submitForm('PUT', '/user/' + userId, data, () => {
              setPopup(null)
            });
            
          }
          else {
            setError('Incorrect password!')
          }
        })
        .catch(e => {
          console.log(e);
          setError(e.message)
        })
      }
  
  
    }
  
    const onCloseClick = e => {
      e.preventDefault();
      setPopup(null)
    }
  
    return (
      <form onSubmit={onSubmit} className='popup w-full' 
      style={{
        maxWidth: '600px ',
        padding: '50px 60px 68px 60px '  
      }}>
        <h4 className='text-start'>‌{ formatMessage('common.changePassword') }</h4>
        <div className='w-full mt-7'>
          <label  className='input-required'>{ formatMessage('common.currentPassword') }</label>
          <PasswordInput 
          name='current_password'
          required
          onChange={handleInputChange}
          />
        </div>
        <div className='w-full mt-10'>
          <label  className='input-required'>{ formatMessage('common.newPassword') }</label>
          <PasswordInput 
          name='password'
          required
          onChange={handleInputChange}
          />
        </div>
        <div className='w-full mt-7'>
          <label className='input-required'>{ formatMessage('common.confirmPassword') }</label>
          <PasswordInput 
          name='confirm_password'
          required
          onChange={handleInputChange}
          />
        </div>
        <div className=' mt-14'>
          { error ? <span className='w-full block text-lg text-red-500 text-center mb-4'>{error}</span> : ''}
          <div className='flex justify-center gap-5'>
            <a onClick={onCloseClick} className='white-btn'>{ formatMessage('common.cancel') }</a>
            <button type='submit' className='black-btn'>{ formatMessage('common.update') }</button>
          </div>
        </div>
      </form>
    )
}

