import { useFormatMessage } from "../hooks/useFormatMessage";
import { useAuth } from "../context/AuthProvider";
import crossIcon from "../img/icon-cross.png";

export default function DatePicker({
  startDateRef,
  endDateRef,
  applyFilter,
}) {
  const formatMessage = useFormatMessage();

  const { setPopup } = useAuth();

  return (
    <div
      className="popup relative"
      style={{ maxWidth: "600px", width: "100%" }}
    >
      <div className="flex flex-col gap-4">
        <div className="w-full">
          <label className="x-label input-required">
            {formatMessage("common.from")}
          </label>
          <input
            required
            type="date"
            name="start_date"
            className="x-input"
            ref={startDateRef}
          />
        </div>
        <div className="w-full">
          <label className="x-label input-required">
            {formatMessage("common.to")}
          </label>
          <input
            required
            type="date"
            name="end_date"
            className="x-input"
            ref={endDateRef}
          />
        </div>
        <div className="w-full flex justify-center mt-4">
          <button className="black-btn" onClick={applyFilter}>
            {formatMessage("common.search")}
          </button>
        </div>
      </div>
      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        src={crossIcon}
        onClick={() => setPopup(null)}
      />
    </div>
  );
}
