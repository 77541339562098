import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL, GeneralStatus } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashCanIcon from "../../img/icon-trash.png";
import detailIcon from "../../img/icon-details.png";
import crossIcon from "../../img/icon-cross.png";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { useAuth } from "../../context/AuthProvider";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";

export default function Currency() {
  const history = useHistory();
  const { setPopup } = useAuth();
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const { data, setCurrentPage, setQuery, reload } = useFetchData({
    url: BACKEND_URL + "/currency",
    defaultQuery: "",
    sort: "currency_name",
  });
  const formatMessage = useFormatMessage();

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  const onSearchChange = (e) => {
    const text = e.target.value;
    setQuery({
      currency_name: text.trim(),
    });
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id
      ? e.target.parentNode.id
      : e.target.parentNode.parentNode.id;
    setPopup(<DeleteConfirmPopup id={id} reload={reload} entity="currency" />);
  };

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
      }
      openedEl.classList.remove("open");
      setisOptionOpen(false);
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  return (
    <div className="page-layout">
      <div className="page-header">
        <h1>{formatMessage("common.currencyList")}</h1>
        <div className="search-bar">
          <img src={searchIcon} />
          <input className="x-input" type="search" onChange={onSearchChange} />
        </div>
        <Link to="/dashboard/currency/create" className="create-button">
          {formatMessage("common.createNew")}
        </Link>
      </div>

      <div className="table-container">
        <table className="x-table">
          <thead>
            <tr>
              <th>{formatMessage("common.currencyName")}</th>
              <th className="button-col"></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((item, i) => {
              const index = (data.page - 1) * data.limit + i + 1;
              return (
                <tr key={i} className="hover-effect">
                  <td>{item.currency_name}</td>
                  <td className="button-col">
                    <div className="flex items-center">
                      <span
                        className={
                          item.status === "ACTIVE"
                            ? "flex mr-7 items-center justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-green-100 text-green-800"
                            : "flex mr-7 items-center justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-gray-100"
                        }
                      >
                        {GeneralStatus[item.status]}
                      </span>
                      <img
                        src={detailIcon}
                        className="col-button"
                        onClick={() => setPopup(<DetailPopup data={item} />)}
                      />
                      <img
                        className="col-button"
                        src={dotsIcon}
                        onClick={() => toggleOptions(item.currency_id)}
                      />
                    </div>
                    <div className="popup-options" id={item.currency_id}>
                      <Link to={`${currentPath}/${item.currency_id}/edit`}>
                        <img src={editIcon} />
                        {formatMessage("common.edit")}
                      </Link>
                      <a href="" onClick={onDeleteClick}>
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

const DetailPopup = ({ data }) => {
  const formatMessage = useFormatMessage();
  const { setPopup } = useAuth();

  return (
    <div className="popup relative">
      <h4 className="mb-10">
        {formatMessage("common.currency")}
        {formatMessage("common.information")}
      </h4>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.currencyName")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.currency_name}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.remark")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.remark}</span>
      </div>

      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        src={crossIcon}
        onClick={() => setPopup(null)}
      />
    </div>
  );
};
