import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatDateToYYYYMMDD } from "../../utils/common";
import { Pagination } from "../../components/Pagination";
import * as XLSX from 'xlsx';

const PurchaseOrderReport = () => {
    // const handleExportClick = (fileName) => {
    //     const table = document.querySelector("#xlsx-export").cloneNode(true);
    //     const workbook = XLSX.utils.table_to_book(table, { display: false });
    //     XLSX.writeFile(workbook, fileName)
    // };


    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);
    
    const formatMessage = useFormatMessage();
    const itemsPerPage = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [currentItems, setCurrentItems] = useState();
    const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(oneMonthAgo));
    const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));
    const [data, setData] = useState({
        page: 1,
        limit: itemsPerPage,
        results: null,
        totalCount: null
    });

    useEffect(() => {
        let url = BACKEND_URL + `/reports/purchaseorderreport?startDate=${startDate}&endDate=${endDate}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData({
                    ...data,
                    results: response.data,
                    totalCount: response.data.data.length
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [startDate, endDate]);

    useEffect(() => {
        setCurrentItems(data.results?.data?.slice(startIndex, endIndex))
    }, [data, currentPage]);

    useEffect(() => {
        setData({ ...data, page: currentPage })
    }, [currentPage]);

    const handleExportClick = (fileName) => {
        const table = document.createElement('table');
        const extraInfo = [
            "", formatMessage("common.from"), startDate, formatMessage("common.to"), endDate
        ];
        
        const headers = [
            formatMessage("common.no"),
            formatMessage("common.purchaseId"),
            formatMessage("common.billId"),
            formatMessage("common.date"),
            formatMessage("common.supplierName"),
            formatMessage("common.totalAmount"),
            formatMessage("common.paidAmount"),
            formatMessage("common.balance"),
            formatMessage("common.dueDate"),

        ];
        const thead = document.createElement('thead');
        const extraHeaderRow = document.createElement('tr');
        extraInfo.forEach(header => {
            const th = document.createElement('th');
            th.innerText = header;
            extraHeaderRow.appendChild(th);
        });
        thead.appendChild(extraHeaderRow);
    
        // Create the header row
       
        const headerRow = document.createElement('tr');
        headers.forEach(header => {
            const th = document.createElement('th');
            th.innerText = header;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        table.appendChild(thead);
    
        // Create the body rows using the entire dataset
        const tbody = document.createElement('tbody');
        data.results.data.forEach((item, index) => {
            const row = document.createElement('tr');
            
            const numberCell = document.createElement('td');
            numberCell.innerText = index + 1;
            row.appendChild(numberCell);
    
            const transactionDateCell = document.createElement('td');
            transactionDateCell.innerText = item["Purchase Order ID"]
            row.appendChild(transactionDateCell);
    
            const salesIncomeCell = document.createElement('td');
            salesIncomeCell.innerText = item["Bill ID"]
            row.appendChild(salesIncomeCell);
    
            const date = document.createElement('td');
            date.innerText = item["Date"]
            row.appendChild(date);
    
            const supplierName = document.createElement('td');
            supplierName.innerText = item["Supplier Name"]
            row.appendChild(supplierName);

            const totalAmount = document.createElement('td');
            totalAmount.innerText = parseInt(item["Total Amount"]).toLocaleString("en-US");
            row.appendChild(totalAmount);

            const paidAmount = document.createElement('td');
            paidAmount.innerText = parseInt(item["Paid Amount"]).toLocaleString("en-US");
            row.appendChild(paidAmount);
            
            const balance = document.createElement('td');
            balance.innerText = parseInt(item["Balance"]).toLocaleString("en-US");
            row.appendChild(balance);
            
            const dueDate = document.createElement('td');
            dueDate.innerText = item["Due Date"]
            row.appendChild(dueDate);
    
            tbody.appendChild(row);
        });
    
        table.appendChild(tbody);
    
        // Create and download the Excel file
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName);
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.purchaseOrderReport")}</h1>
                <div className="flex">
                    <input
                        required
                        name="start_date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        className="x-input mr-3"
                        type="date"
                    />
                    <input
                        required
                        name="end_date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        className="x-input mr-4"
                        type="date"
                    />

                    <button className="header-img-button" onClick={() => {
                        handleExportClick(`Purchase_Order_Report_from_${startDate}_to_${endDate}.xlsx`)
                    }}>
                        <img src={printIcon} />
                    </button>
                </div>
            </div>
            <div className="table-container">
                <table className="x-table" id="xlsx-export">
                    <thead>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={5}></th>
                            <th className="hidden">{formatMessage("common.from")}</th>
                            <th className="hidden">{startDate}</th>
                            <th className="hidden">{formatMessage("common.to")}</th>
                            <th className="hidden">{endDate}</th>
                        </tr>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={9}></th>
                        </tr>
                        <tr>
                            <th>{formatMessage("common.no")}</th>
                            <th>{formatMessage("common.purchaseId")}</th>
                            <th>{formatMessage("common.billId")}</th>
                            <th>{formatMessage("common.date")}</th>
                            <th>{formatMessage("common.supplierName")}</th>
                            <th>{formatMessage("common.totalAmount")}</th>
                            <th>{formatMessage("common.paidAmount")}</th>
                            <th>{formatMessage("common.balance")}</th>
                            <th>{formatMessage("common.dueDate")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((item, i) => {
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{i + 1}</td>
                                    <td>{item["Purchase Order ID"]}</td>
                                    <td>{item["Bill ID"]}</td>
                                    <td>{item["Date"]}</td>
                                    <td>{item["Supplier Name"]}</td>
                                    <td>{parseInt(item["Total Amount"]).toLocaleString("en-US")}</td>
                                    <td>{parseInt(item["Paid Amount"]).toLocaleString("en-US")}</td>
                                    <td>{parseInt(item["Balance"]).toLocaleString("en-US")}</td>
                                    <td>{item["Due Date"]}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                <Pagination data={data} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
}

export default PurchaseOrderReport;
