import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BACKEND_URL } from "../../utils/constant";
import axios from "axios";
import DataDropdown from "../../components/DataDropdown";
import NotFound from "../NotFound";
import DefaultDropdown from "../../components/DefaultDropdown";

export default function CreatePaymentReceive() {
    const { handleInputChange, formData, setFormData, submitForm, error } = useForm();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const {invoice_id} = useParams();
    const { user, setLoading } = useAuth();
    const paymentMethods = [
        {
            value: 'cash',
            name: "Cash"
        },
        {
            value: 'bank',
            name: "Bank"
        }
    ]

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        submitForm("POST", "/paymentReceive", data, () => {
            history.push("/dashboard/paymentReceive");
        });
    };


    useEffect(() => {
        (async () => {

            let url = BACKEND_URL + "/invoice?invoice_id=" + invoice_id;
            let response = await axios.get(
                url,
                {
                    withCredentials: true,
                }
            );
            let invoice = response.data.data;
            if (!invoice || invoice.length == 0)
            {
                return;
            }
            invoice = invoice[0]
            setFormData({
                ...formData,
                invoice,
                invoice_number: invoice.invoice_number,
                invoice_id: invoice.invoice_id
            })
        })();
    }, []);

    if (!formData.invoice_id)
    {
        return <NotFound/>
    }


    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/paymentReceive">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createPaymentReceive")}
                </h1>
                <h4 className="text-xl mt-5">
                    {formatMessage("common.paymentReceive")} {formatMessage("common.information")}
                </h4>

                <div className="pl-4 w-96 mt-6">
                    <div className="flex justify-between gap-6">
                        <span className="block flex-grow w-full">{ formatMessage('common.remainingAmount') }</span>
                        <span className="block flex-grow ">:</span>
                        <span className="block flex-grow w-full">{formData.invoice.remainingAmount}</span>
                    </div>
                    <div className="flex mt-4 justify-between gap-6">
                        <span className="block flex-grow w-full">{ formatMessage('common.totalAmount') }</span>
                        <span className="block flex-grow">:</span>
                        <span className="block flex-grow w-full">{formData.invoice.total_amount}</span>
                    </div>
                </div>

                <div className="form-container">
                    <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.invoiceNumber")}
                                </label>
                                <input
                                    required
                                    disabled
                                    type="text"
                                    value={formData.invoice_number || ""}
                                    name="invoice_number"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.receiveAmount")}
                                </label>
                                <input
                                    required
                                    type="number"
                                    name="receive_amount"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.paymentMethod")}
                                </label>
                                <DataDropdown
                                    required
                                    value={formData.payment_method || ""}
                                    name="payment_method"
                                    data={paymentMethods}
                                    title="name"
                                    id="value"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.transactionDate")}
                                </label>
                                <input
                                    required
                                    type="date"
                                    name="transaction_date"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.paidThrough")}
                                </label>
                                <DefaultDropdown
                                    required
                                    value={formData.paid_through || ""}
                                    name="paid_through"
                                    entity="finance/paymentPaidThroughAccount"
                                    title="sub_account_name"
                                    id="sub_account_id"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/paymentReceive`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
