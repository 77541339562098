import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import DefaultDropdown from "../../components/DefaultDropdown";
import DataDropdown from "../../components/DataDropdown";
import { TransactionTypes } from "../../utils/constant";

export default function CreateBankTransaction() {
    const { handleInputChange, formData, setFormData, submitForm, error } =
        useForm();

        const [currency, setcurrency] = useState()


    const [CXList, setCXList] = useState([]);
    const [bankAccount, setbankAccount] = useState();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
        };
        submitForm("POST", "/bankTransaction", data, () => {
            history.push("/dashboard/bankTransaction");
        });
    };

    useEffect(() => {
        if (CXList.length !== 0 && formData.cx_rate_id) {
            let tempCX = CXList.filter(
                (d) => d.cx_rate_id == formData.cx_rate_id
            )[0];
            let rate = tempCX.converted_unit / tempCX.base_unit;
            setFormData({
                ...formData,
                yun_trx_amount: formData.mmk_trx_amount * rate,
            });
        }
    }, [formData.cx_rate_id]);

    const cnyInputKeyUp = (e) => {
        if (formData.cx_rate_id) {
            let cxRate = CXList.filter(
                (d) => d.cx_rate_id == formData.cx_rate_id
            )[0];
            let cnyPrice = e.target.value;
            let rate = cxRate.base_unit / cxRate.converted_unit;
            setFormData({
                ...formData,
                mmk_trx_amount: cnyPrice * rate,
            });
        }
    };

    const mmkInputKeyUp = (e) => {
        if (formData.cx_rate_id) {
            let cxRate = CXList.filter(
                (d) => d.cx_rate_id == formData.cx_rate_id
            )[0];
            let mmkPrice = e.target.value;
            let rate = cxRate.converted_unit / cxRate.base_unit;
            setFormData({
                ...formData,
                yun_trx_amount: mmkPrice * rate,
            });
        }
    };

    useEffect(() => {
        if (!bankAccount) return;

        setFormData({
            ...formData,
            bank_account_holder_name: bankAccount.filter(
                (d) => d.bank_account_id == formData.bank_account_id
            )[0].bank_account_holder_name,
        });
    }, [formData.bank_account_id]);

    // useEffect(() => {
    //     if (CXList.length !== 0 && formData.cx_rate_id) {
    //         let mmkRate = CXList.filter(
    //             (d) => d.cx_rate_id == formData.cx_rate_id
    //         )[0].base_unit;
    //         setFormData({
    //             ...formData,
    //             mmk_trx_amount: formData.yun_trx_amount * mmkRate,
    //         });
    //     }
    // }, [formData.yun_trx_amount]);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/bankTransaction">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createBankTransaction")}
                </h1>
                <div className="form-container">
                    <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.bankName")}
                                </label>
                                <DefaultDropdown
                                    onChange={handleInputChange}
                                    value={formData.bank_id}
                                    required
                                    entity="bank"
                                    id="bank_id"
                                    title="bank_name"
                                    name="bank_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.bankAccount")}
                                </label>
                                <DefaultDropdown
                                    onChange={handleInputChange}
                                    value={formData.bank_account_id}
                                    setData={setbankAccount}
                                    required
                                    extQuery={
                                        formData.bank_id
                                            ? `&bank_id=${formData.bank_id}`
                                            : ""
                                    }
                                    entity="bankAccount"
                                    id="bank_account_id"
                                    title="bank_account"
                                    name="bank_account_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.transactionDate")}
                                </label>
                                <input
                                    required
                                    type="date"
                                    name="bank_trx_date"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage(
                                        "common.bankAccountHolderName"
                                    )}
                                </label>
                                <input
                                    required
                                    disabled
                                    type="text"
                                    value={formData.bank_account_holder_name ? formData.bank_account_holder_name : ''}
                                    name="bank_account_holder_name"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.transactionType")}
                                </label>
                                <DataDropdown
                                    id="id"
                                    title="type"
                                    data={TransactionTypes}
                                    name="trx_type"
                                    value={formData.trx_type}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grow"></div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.currency")}
                                </label>
                                <DefaultDropdown
                                    required
                                    extQuery={`&base_currency_flag=NO`}
                                    value={formData.currency_id}
                                    entity="currency"
                                    setData={setcurrency}
                                    name="currency_id"
                                    onChange={handleInputChange}
                                    title="currency_name"
                                    id="currency_id"
                                />
                            </div>


                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.currencyExchange")}
                                </label>
                                <DefaultDropdown
                                    setData={setCXList}
                                    onChange={handleInputChange}
                                    value={formData.cx_rate_id}
                                    extQuery={`&exchange_date=${formData.bank_trx_date}&converted_currency_id=${formData.currency_id}`}
                                    required
                                    entity="currencyExchange"
                                    id="cx_rate_id"
                                    title="base_unit"
                                    name="cx_rate_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage(
                                        "common.mmkTransactionAmount"
                                    )}
                                </label>
                                <input
                                    required
                                    type="number"
                                    value={formData.mmk_trx_amount || ""}
                                    name="mmk_trx_amount"
                                    onKeyUp={mmkInputKeyUp}
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage(
                                        "common.cynTransactionAmount"
                                    )}
                                </label>
                                <input
                                    required
                                    value={formData.yun_trx_amount || ""}
                                    type="number"
                                    onKeyUp={cnyInputKeyUp}
                                    name="yun_trx_amount"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/bankTransaction`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
