import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import DefaultDropdown from "../../components/DefaultDropdown";
import { decimalplaces } from "../../utils/constant";

export default function CreateCurrencyExchange() {
    const { handleInputChange, formData, submitForm, error } = useForm();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            "converted_unit": 1,
            status: "ACTIVE",
        };

        submitForm("POST", "/currencyExchange", data, () => {
            history.push("/dashboard/currencyExchange");
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/currencyExchange">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createCurrencyExchange")}
                </h1>
                <div className="form-container">
                    <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.baseCurrency")}
                                </label>
                                <DefaultDropdown
                                    onChange={handleInputChange}
                                    extQuery={'&base_currency_flag=YES'}
                                    value={formData.base_currency_id}
                                    required
                                    entity="currency"
                                    id="currency_id"
                                    title="currency_name"
                                    name="base_currency_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.baseAmount")}
                                </label>
                                <input
                                    required
                                    type="number"
                                    name="base_unit"
                                    step={decimalplaces}
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.convertedCurrency")}
                                </label>
                                <DefaultDropdown
                                    onChange={handleInputChange}
                                    value={formData.converted_currency_id}
                                    required
                                    extQuery={'&base_currency_flag=NO'} 
                                    entity="currency"
                                    id="currency_id"
                                    title="currency_name"
                                    name="converted_currency_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.convertedAmount")}
                                </label>
                                <input
                                    required
                                    type="number"
                                    name="converted_unit"
                                    value={1}
                                    disabled
                                    readOnly
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.exchangeDate")}
                                </label>
                                <input
                                    required
                                    type="date"
                                    name="exchange_date"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/currencyExchange`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
