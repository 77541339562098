import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import DefaultDropdown from "../../components/DefaultDropdown";
import DataDropdown from "../../components/DataDropdown";

export default function CreateBankAccount() {
    const { handleInputChange, formData, submitForm, error } = useForm();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();

    const currencyList = [
        {
            name: 'MMK',
            value: 'MMK'
        },
        {
            name: 'CNY',
            value: 'CNY'
        },
    ]

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        submitForm("POST", "/bankAccount", data, () => {
            history.push("/dashboard/bankAccount");
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/bankAccount">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createBankAccount")}
                </h1>
                <div className="form-container">
                    <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.bankName")}
                                </label>
                                <DefaultDropdown
                                    onChange={handleInputChange}
                                    value={formData.bank_id}
                                    required
                                    entity="bank"
                                    id="bank_id"
                                    title="bank_name"
                                    name="bank_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.accountNumber")}
                                </label>
                                <input
                                    required
                                    type="number"
                                    name="bank_account"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.currency")}
                                </label>
                                <DataDropdown
                                    required
                                    data={currencyList}
                                    name="currency"
                                    title="name"
                                    id="value"
                                    value={formData.currency || ""}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.balance")}
                                </label>
                                <input
                                    required
                                    type="number"
                                    name="balance"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.accountHolderName")}
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="bank_account_holder_name"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/bankAccount`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
