import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { formatCurrency } from "../utils/common";
import { useEffect, useState } from "react";
import { decimalplaces } from "../utils/constant";

export default function InvoiceProductTable({
  formData,
  cxRate,
  currency,
  setFormdata,
}) {
  const formatMessage = useFormatMessage();
  const history = useHistory();
  let currencyName = "";
  if (currency && formData.currency_id) {
    currencyName = currency.filter(
      (c) => formData.currency_id == c.currency_id
    )[0].currency_name;
  }

  const addProduct = (e) => {
    e.preventDefault();
    history.push("/dashboard/purchaseOrder/create/addProduct", {
      routerPayload: { ...formData },
    });
  };
  const handleInputChange = (index) => (e) => {
    let temp = [...formData.items];
    let { name, value } = e.target;
    temp[index] = {
      ...temp[index],
      [name]: value,
    };
    setFormdata({
      ...formData,
      items: temp,
    });
  };

  const convertedInputKeyUp = (index) => (e) => {
    if (formData.cx_rate_id) {
      let cnyPrice = e.target.value;
      let currentCx = cxRate.filter(
        (cx) => cx.cx_rate_id == formData.cx_rate_id
      )[0];
      let rate = currentCx.base_unit / currentCx.converted_unit;

      let tempItems = [...formData.items];
      let xitem = tempItems[index];
      xitem.selling_price = Math.round(cnyPrice * rate * 1000) / 1000;

      setFormdata({
        ...formData,
        items: tempItems,
      });
    }
  };

  const mmkInputKeyUp = (index) => (e) => {
    if (formData.cx_rate_id) {
      let mmkPrice = e.target.value;
      let currentCx = cxRate.filter(
        (cx) => cx.cx_rate_id == formData.cx_rate_id
      )[0];
      let rate = currentCx.base_unit / currentCx.converted_unit;

      let tempItems = [...formData.items];
      let xitem = tempItems[index];
      xitem.selling_price_converted = Math.round(mmkPrice * rate * 1000) / 1000;

      setFormdata({
        ...formData,
        items: tempItems,
      });
    }
  };

  useEffect(() => {
    if (formData.cx_rate_id && cxRate) {
      let xtemp = [...formData.items];
      xtemp = xtemp.map((v) => {
        let xprice = v.selling_price_converted;
        let currentCx = cxRate.filter(
          (cx) => cx.cx_rate_id == formData.cx_rate_id
        )[0];
        let rate = currentCx.base_unit / currentCx.converted_unit;

        v.selling_price = Math.round(xprice * rate * 1000) / 1000;
        return v;
      });

      setFormdata({
        ...formData,
        items: xtemp,
      });
    }
  }, [formData.cx_rate_id]);

  return (
    <div className="flex flex-col items-end mt-6">
      <table className="x-table mt-6 x-table-center">
        <thead>
          <tr>
            <td>{formatMessage("common.productCategory")}</td>
            <td>{formatMessage("common.productName")}</td>
            <td>{formatMessage("common.quantity")}</td>
            <td>{formatMessage("common.unit")}</td>
            <td>{formatMessage("common.purchasePriceMMK")}</td>
            <td>
              {formatMessage("common.sellingPrice")} {currencyName}
            </td>
            <td>{formatMessage("common.sellingPrice")} MMK</td>
          </tr>
        </thead>
        <tbody>
          {formData.items &&
            formData.items.map((item, index) => {
              return (
                <tr
                  key={index}
                  className=""
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <td>
                    <span>{item.productCategory.product_category_name}</span>
                  </td>
                  <td>
                    <span>{item.product_name}</span>
                  </td>
                  <td>
                    <span>{item.quantity}</span>
                  </td>
                  <td>
                    <span>{item.unit?.unit_name ?? ""}</span>
                  </td>
                  <td>
                    <input
                      type="number"
                      required
                      // value={item.purchase_price_mmk || 0}
                      value={item.purchase_price_mmk}
                      step={decimalplaces}
                      name="purchase_price_mmk"
                      onChange={handleInputChange(index)}
                      className="x-input border-2 border-rose-500"
                    />
                    <td></td>
                  </td>

                  <td>
                    <input
                      type="number"
                      required
                      step={decimalplaces}
                      onKeyUp={convertedInputKeyUp(index)}
                      // value={
                      //     item.selling_price_converted ||
                      //     0
                      // }
                      value={item.selling_price_converted}
                      name="selling_price_converted"
                      onChange={handleInputChange(index)}
                      className="x-input"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      required
                      onKeyUp={mmkInputKeyUp(index)}
                      // value={item.selling_price || 0}
                      value={item.selling_price}
                      step={decimalplaces}
                      name="selling_price"
                      onChange={handleInputChange(index)}
                      className="x-input"
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
