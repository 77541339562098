import { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { formatCurrency } from "../utils/common";
import dotsIcon from "../img/icondots.png";
import editIcon from "../img/icon-edit.png";
import trashCanIcon from "../img/icon-trash.png";
import crossIcon from "../img/icon-cross.png";
import DefaultDropdown from "./DefaultDropdown";
import { useAuth } from "../context/AuthProvider";
import { decimalplaces } from "../utils/constant";

export default function SaleProductForm({ formData, setFormData }) {
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const { setPopup } = useAuth();
  const location = useLocation();
  const addProduct = (e) => {
    e.preventDefault();
    history.push("/dashboard/saleOrder/create/addProduct", {
      routerPayload: { ...formData },
    });
  };

  useEffect(() => {
    if (location.state && location.state.routerPayload.items) {
      setFormData({
        ...formData,
        items: location.state.routerPayload.items,
      });
    }
  }, [location.state]);

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
      }
      openedEl.classList.remove("open");
      setisOptionOpen(false);
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  const handleRemove = (id) => {
    const filterItems = formData?.items.filter((item) => item.id != id);
    history.replace({
      ...location,
      state: {
        ...location.state,
        routerPayload: {
          items: filterItems,
        },
      },
    });
  };

  return (
    <div className="flex flex-col items-end">
      <a onClick={addProduct} className="black-btn whitespace-nowrap">
        + {formatMessage("common.addProduct")}
      </a>

      <table className="x-table mt-6 x-table-center">
        <thead>
          <tr>
            <td>{formatMessage("common.productCategory")}</td>
            <td>{formatMessage("common.productName")}</td>
            <td>{formatMessage("common.quantity")}</td>
            <td>{formatMessage("common.unit")}</td>
            <td>{formatMessage("common.saleType")}</td>
            <td className="button-col"></td>
          </tr>
        </thead>
        <tbody>
          {formData.items &&
            formData.items.map((item, index) => {
              return (
                <tr
                  key={index}
                  className=""
                  style={{
                    paddingTop: "25px",
                    paddingBottom: "25px",
                  }}
                >
                  <td>
                    <span>
                      {item.product_category_name ||
                        item.productCategory.product_category_name}
                    </span>
                  </td>
                  <td>
                    <span>{item.product_name}</span>
                  </td>
                  <td>
                    <span>{item.product_qty}</span>
                  </td>
                  <td>
                    <span>{item.unit_name ?? item.unit?.unit_name ?? ""}</span>
                  </td>
                  <td>
                    <span>{formatCurrency(item.sale_type_id)}</span>
                  </td>
                  <td className="button-col">
                    <div className="flex">
                      <img
                        alt=""
                        className="col-button"
                        src={dotsIcon}
                        onClick={() => toggleOptions(item.product_category_id)}
                      />
                    </div>
                    <div
                      className="popup-options"
                      id={item.product_category_id}
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setPopup(
                            <DetailPopup
                              data={item}
                              items={formData.items}
                              history={history}
                            />
                          );
                        }}
                      >
                        <img src={editIcon} />
                        <span>{formatMessage("common.edit")}</span>
                      </a>

                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemove(item.id);
                        }}
                      >
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

const DetailPopup = ({ data, items, history }) => {
  console.log(data);
  const [productCategory, setProductCategory] = useState();
  const [unit, setUnit] = useState();
  const [formData, setFormData] = useState({ ...data });

  const formatMessage = useFormatMessage();
  const { setPopup } = useAuth();
  const updateElementById = (id, updatedData) => {
    return items.map((item) =>
      item.id === id ? { ...item, ...updatedData } : item
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let updateArray = updateElementById(formData?.id, formData);
    const currentLocation = history.location;

    history.replace({
      ...currentLocation,
      state: {
        ...currentLocation.state,
        routerPayload: {
          items: updateArray,
        },
      },
    });
    setPopup(null);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    let newData = {
      [name]: value.trim(),
    };

    if (name == "product_category_id") {
      let temp = productCategory.filter((d) => d.product_category_id == value);
      newData.product_category_name = temp[0]
        ? temp[0].product_category_name
        : "";
    }

    if (name == "unit_id") {
      let temp = unit.filter((d) => d.unit_id == value);
      newData.unit_name = temp[0] ? temp[0].unit_name : "";
    }

    setFormData({ ...formData, ...newData });
  };
  return (
    <div
      className="popup relative"
      style={{ maxWidth: "1080px", width: "100%" }}
    >
      <div className="container flex flex-col">
        <div className="mb-10">
          <h1 className="sub-page-title">
            {formatMessage("common.editPurchaseOrder")}
          </h1>
        </div>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productCategory")}
                </label>
                <DefaultDropdown
                  required
                  setData={setProductCategory}
                  value={formData.product_category_id || ""}
                  name="product_category_id"
                  onChange={handleInputChange}
                  entity="productCategory"
                  id="product_category_id"
                  title="product_category_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productName")}
                </label>
                <input
                  required
                  type="text"
                  name="product_name"
                  value={formData.product_name || ""}
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.quantity")}
                </label>
                <input
                  required
                  type="number"
                  step={decimalplaces}
                  value={formData.product_qty || ""}
                  name="product_qty"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productCategory")}
                </label>
                <DefaultDropdown
                  required
                  setData={setUnit}
                  value={formData.unit_id || ""}
                  name="unit_id"
                  onChange={handleInputChange}
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.saleType")}
                </label>
                <select
                  required
                  name="sale_type_id"
                  onChange={handleInputChange}
                  className="x-input"
                  value={formData.sale_type_id}
                >
                  <option value=""></option>
                  <option value="Prepaid">Prepaid</option>
                  <option value="Postpaid">Postpaid</option>
                </select>
              </div>

              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                  value={formData.remark}
                />
              </div>
            </div>

            <div className="flex justify-end gap-5 mt-10">
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setPopup(null);
                }}
                className="white-btn"
              >
                {formatMessage("common.cancel")}
              </a>
              <button className="black-btn">
                {formatMessage("common.update")}
              </button>
            </div>
          </form>
        </div>
      </div>

      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        onClick={() => setPopup(null)}
        src={crossIcon}
        alt=""
      />
    </div>
  );
};
