import  axios  from 'axios'
import { useState, useEffect } from 'react'
import { BACKEND_URL } from '../utils/constant'

export const useCheckUser = () => {
    const [user, setUser] = useState('')
    const [isLoading, setisLoading] = useState(true)
    
    const checkUser = (callback = ()=> {}) => {
        const localData = JSON.parse(localStorage.getItem('user')) ?? {}
        const authUrl = BACKEND_URL + '/auth/me'
        axios.defaults.headers.common['Authorization'] = localData?.token
        axios.get(authUrl, {withCredentials: true})
        .then(response => {
            setUser({
                ...response.data.data,
                userAccess: [...response.data.data.role.userAccess]
                // userAccess: [...response.data.data.role.userAccess, 'Finance Management']
            })
            setisLoading(false)
            callback();
        })
        .catch(error => {
            
            setUser(null)
            setisLoading(false)
        });
        
    }

    useEffect(() => {
        checkUser(() => {});
    }, [])

    return {
        user,
        setUser,
        isLoading,
        checkUser
    }

}
