import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashCanIcon from "../../img/icon-trash.png";
import detailIcon from "../../img/icon-details.png";
import crossIcon from "../../img/icon-cross.png";
import reloadIcon from "../../img/icon-reload.png";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { useAuth } from "../../context/AuthProvider";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";
import { ResetPasswordPopup } from "../../components/ResetPasswordPopup";

function OfficeUser() {
  const history = useHistory();
  const { setPopup } = useAuth();
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const { data, setCurrentPage, setQuery, reload } = useFetchData({
    url: BACKEND_URL + "/user",
    defaultQuery: "status=ACTIVE",
  });
  const formatMessage = useFormatMessage();

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  const onSearchChange = (e) => {
    const text = e.target.value;
    setQuery({
      name: text.trim(),
    });
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id
      ? e.target.parentNode.id
      : e.target.parentNode.parentNode.id;
    setPopup(<DeleteConfirmPopup id={id} reload={reload} entity="user" />);
  };

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
      }
      openedEl.classList.remove("open");
      setisOptionOpen(false);
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  const onResetClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id
      ? e.target.parentNode.id
      : e.target.parentNode.parentNode.id;
    setPopup(<ResetPasswordPopup id={id} />);
  };

  return (
    <div className="page-layout">
      <div className="page-header">
        <h1>{formatMessage("common.officeUserList")}</h1>
        <div className="search-bar">
          <img src={searchIcon} />
          <input className="x-input" type="search" onChange={onSearchChange} />
        </div>
        <Link to="/dashboard/officeUser/create" className="create-button">
          {formatMessage("common.createNew")}
        </Link>
      </div>

      <div className="table-container">
        <table className="x-table">
          <thead>
            <tr>
              <th>{formatMessage("common.name")}</th>
              <th>{formatMessage("common.username")}</th>
              <th>{formatMessage("common.userRole")}</th>
              <th>{formatMessage("common.phone")}</th>
              <th className="button-col"></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((item, i) => {
              const index = (data.page - 1) * data.limit + i + 1;
              return (
                <tr key={i} className="hover-effect">
                  <td>{item.name}</td>
                  <td>{item.username}</td>
                  <td>{item.role?.role_name}</td>
                  <td>{item.user_phone1}</td>
                  <td className="button-col">
                    <div className="flex">
                      <img
                        src={detailIcon}
                        className="col-button"
                        onClick={() => setPopup(<DetailPopup data={item} />)}
                      />
                      <img
                        className="col-button"
                        src={dotsIcon}
                        onClick={() => toggleOptions(item.user_id)}
                      />
                    </div>
                    <div className="popup-options" id={item.user_id}>
                      <Link to={`${currentPath}/${item.user_id}/edit`}>
                        <img src={editIcon} />
                        {formatMessage("common.edit")}
                      </Link>
                      <a href="" onClick={onResetClick}>
                        <img src={reloadIcon} />
                        {formatMessage("common.resetPassword")}
                      </a>
                      <a href="" onClick={onDeleteClick}>
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

const DetailPopup = ({ data }) => {
  const formatMessage = useFormatMessage();
  const { setPopup } = useAuth();

  return (
    <div className="popup relative">
      <h4 className="mb-10">{formatMessage("common.officeUserInformation")}</h4>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.name")}
        </span>
        <span className="mr-20">-</span>
        <span className="inline-block w-52">{data.name}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.username")}
        </span>
        <span className="mr-20">-</span>
        <span className="inline-block w-52">{data.username}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.userRole")}
        </span>
        <span className="mr-20">-</span>
        <span className="inline-block w-52">{data.role.role_name}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.phone")}
        </span>
        <span className="mr-20">-</span>
        <span className="inline-block w-52">{data.user_phone1}</span>
      </div>

      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        src={crossIcon}
        onClick={() => setPopup(null)}
      />
    </div>
  );
};

export default OfficeUser;
