import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DefaultDropdown from "../../components/DefaultDropdown";
import axios from "axios";
import { BACKEND_URL, decimalplaces } from "../../utils/constant";
import { isValidInput } from "../../utils/common";

export default function AddPurchaseProduct({ sale_order_id }) {
  const formatMessage = useFormatMessage();
  const [formData, setFormData] = useState({});
  const [errorYun, setErrorYun] = useState("");
  const [errorMmk, setErrorMmk] = useState("");

  const [productCategory, setProductCategory] = useState();
  const [unit, setUnit] = useState();
  const [cxRate, setCxRate] = useState(null);
  const history = useHistory();
  const { state } = useLocation();
  let prevData = {
    items: [],
  };

  if (state && state.routerPayload) {
    prevData = state.routerPayload;
  }

  const redirect_url = prevData.purchase_order_id
    ? `/dashboard/purchaseOrder/${prevData.purchase_order_id}/edit`
    : "/dashboard/purchaseOrder/create";

  useEffect(() => {
    if (prevData.cx_rate_id) {
      let url =
        BACKEND_URL +
        "/currencyExchange?status=ACTIVE&included=true&&cx_rate_id=" +
        prevData.cx_rate_id;
      axios
        .get(url, {
          withCredentials: true,
        })
        .then((response) => {
          let temp = response.data.data[0];
          console.log({ temp });
          setCxRate({ ...temp });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let newData = {
      [name]: value.trim(),
    };
    if (name === "product_category_id") {
      let temp = productCategory.filter((d) => d.product_category_id === value);
      newData.product_category_name = temp[0]
        ? temp[0].product_category_name
        : "";
    }

    if (name === "unit_id") {
      let temp = unit.filter((d) => d.unit_id === value);
      newData.unit_name = temp[0] ? temp[0].unit_name : "";
    }

    console.log(formData);
    setFormData({
      ...formData,
      ...newData,
    });
  };

  const cnyInputKeyUp = (e) => {
    if (cxRate) {
      let cnyPrice = e.target.value;
      let rate = cxRate.base_unit / cxRate.converted_unit;
      let mmkPrice = cnyPrice * rate;
      setFormData({
        ...formData,
        purchase_price_mmk: Math.round(mmkPrice * 1000) / 1000,
      });
    }
  };

  const mmkInputKeyUp = (e) => {
    if (cxRate) {
      let mmkPrice = e.target.value;
      let rate = cxRate.converted_unit / cxRate.base_unit;
      let cnyPrice = mmkPrice * rate;
      setFormData({
        ...formData,
        purchase_price_yun: Math.round(cnyPrice * 1000) / 1000,
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValidInput(formData.purchase_price_yun)) {
      setErrorYun("Input value should be three decimal");
      return;
    }
    if (!isValidInput(formData.purchase_price_mmk)) {
      setErrorMmk("Input value should be three decimal");
      return;
    }
    setErrorYun("");
    setErrorMmk("");
    prevData.items.push(formData);
    history.push(redirect_url, {
      routerPayload: prevData,
    });
  };

  const backClick = (e) => {
    e.preventDefault();
    history.push(redirect_url, {
      routerPayload: prevData,
    });
  };

  return (
    <div className="sub-page-layout-1">
      <a className="back-to-page" onClick={backClick}>
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </a>
      <div className="container flex flex-col">
        <div>
          <h1 className="sub-page-title">
            {formatMessage("common.createPurchaseOrder")}
          </h1>

          <h4 className="text-xl mt-5">
            {formatMessage("common.addNewProduct")}
          </h4>
        </div>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productCategory")}
                </label>
                <DefaultDropdown
                  required
                  setData={setProductCategory}
                  value={formData.product_category_id || ""}
                  name="product_category_id"
                  onChange={handleInputChange}
                  entity="productCategory"
                  id="product_category_id"
                  title="product_category_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productName")}
                </label>
                <input
                  required
                  type="text"
                  name="product_name"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.quantity")}
                </label>
                <input
                  required
                  type="number"
                  step={decimalplaces}
                  name="quantity"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.unit")}
                </label>
                <DefaultDropdown
                  required
                  setData={setUnit}
                  value={formData.unit_id || ""}
                  name="unit_id"
                  onChange={handleInputChange}
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchasePrice") +
                    (cxRate != null
                      ? cxRate.convertedCurrency.currency_name
                      : "")}
                </label>
                <input
                  required
                  type="number"
                  step={decimalplaces}
                  value={formData.purchase_price_yun || ""}
                  name="purchase_price_yun"
                  onChange={handleInputChange}
                  onKeyUp={cnyInputKeyUp}
                  className="x-input"
                />
                {errorYun && (
                  <div className="text-red-500 mt-2">{errorYun}</div>
                )}
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchasePriceMMK")}
                </label>
                <input
                  required
                  type="number"
                  step={decimalplaces}
                  value={formData.purchase_price_mmk || ""}
                  onChange={handleInputChange}
                  onKeyUp={mmkInputKeyUp}
                  className="x-input"
                  name="purchase_price_mmk"
                />
                {errorMmk && (
                  <div className="text-red-500 mt-2">{errorMmk}</div>
                )}
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>

            <div>
              <div className="flex justify-end gap-5">
                <a href="" onClick={backClick} className="white-btn">
                  {formatMessage("common.cancel")}
                </a>
                <button type="submit" className="black-btn">
                  {formatMessage("common.create")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
