import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL, GeneralStatus } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashCanIcon from "../../img/icon-trash.png";
import detailIcon from "../../img/icon-details.png";
import crossIcon from "../../img/icon-cross.png";
import reloadIcon from "../../img/icon-reload.png";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { useAuth } from "../../context/AuthProvider";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";
import { ResetPasswordPopup } from "../../components/ResetPasswordPopup";

export default function Bank() {
    const history = useHistory();
    const { setPopup } = useAuth();
    const currentPath = history.location.pathname;
    const [isOptionOpen, setisOptionOpen] = useState(false);
    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: BACKEND_URL + "/bank",
        defaultQuery: "",
    });
    const formatMessage = useFormatMessage();

    const toggleOptions = (id) => {
        const optionEl = document.getElementById(id);
        const activeOptionEl = document.querySelectorAll(".popup-options.open");
        console.log(activeOptionEl);
        if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
            optionEl.classList.toggle("open");
            setisOptionOpen(optionEl.classList.contains("open"));
        }
    };

    const onSearchChange = (e) => {
        const text = e.target.value;
        setQuery({
            bank_name: text.trim(),
        });
    };

    const onDeleteClick = (e) => {
        e.preventDefault();
        const id = e.target.parentNode.id
            ? e.target.parentNode.id
            : e.target.parentNode.parentNode.id;
        setPopup(<DeleteConfirmPopup id={id} reload={reload} entity="bank" />);
    };

    useEffect(() => {
        const openedEl = document.querySelector(".popup-options.open");

        const handleDocumentClick = (e) => {
            if (e.target != openedEl && !openedEl.contains(e.target)) {
            }
            openedEl.classList.remove("open");
            setisOptionOpen(false);
        };
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener("click", handleDocumentClick);
            }, 100);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOptionOpen]);

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>{formatMessage("common.bankList")}</h1>
                <div className="search-bar">
                    <img src={searchIcon} />
                    <input
                        className="x-input"
                        type="search"
                        onChange={onSearchChange}
                    />
                </div>
                <Link to="/dashboard/bank/create" className="create-button">
                    {formatMessage("common.createNew")}
                </Link>
            </div>

            <div className="table-container">
                <table className="x-table">
                    <thead>
                        <tr>
                            <th>{formatMessage("common.bankName")}</th>
                            <th className="button-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.results.map((item, i) => {
                            const index = (data.page - 1) * data.limit + i + 1;
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{item.bank_name}</td>
                                    <td className="button-col">
                                        <div className="flex items-center">
                                            <span
                                                className={
                                                    item.status === "ACTIVE"
                                                        ? "flex mr-7 items-center justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-green-100 text-green-800"
                                                        : "flex mr-7 items-center justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-gray-100"
                                                }
                                            >
                                                {GeneralStatus[item.status]}
                                            </span>

                                            <img
                                                className="col-button"
                                                src={dotsIcon}
                                                onClick={
                                                    item.status == "ACTIVE"
                                                        ? () =>
                                                              toggleOptions(
                                                                  item.merchant_id
                                                              )
                                                        : null
                                                }
                                            />
                                        </div>
                                        <div
                                            className="popup-options"
                                            id={item.bank_id}
                                        >
                                            <Link
                                                to={`${currentPath}/${item.bank_id}/edit`}
                                            >
                                                <img src={editIcon} />
                                                {formatMessage("common.edit")}
                                            </Link>
                                            <a href="" onClick={onDeleteClick}>
                                                <img src={trashCanIcon} />
                                                {formatMessage("common.remove")}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="pagination-container">
                <Pagination data={data} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
}
