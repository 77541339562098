import moment from "moment"

export const allow = (data, module) => {
    const xtemp = data.filter(item => item == module)
    if (xtemp.length > 0)
    {
        return true
    }
    else
    {
        return false
    }
}

export const formatCurrency = (value, removePoint = true) => {
    if (!value)
    {
        return 0;
    }

    value = value.toString();
    var parts = value.split(".");
    var wholeNumber = parts[0];
    var decimal = parts.length > 1 && !removePoint ? "." + parts[1] : "";
    
    var regex = /(\d)(?=(\d{3})+$)/g;
    wholeNumber = wholeNumber.replace(regex, "$1,");
    
    return wholeNumber + decimal;
}
  


export const getSelectableYears = () => {
    let now = moment().format('YYYY') * 1;
    let years = [];
    for(let i = 2023; i <= now + 100; i++)
    {
        years.push(i)
    }
    return years
}

export const getFullMonth = (m) => {
    return moment(m, 'M').format('MMMM')
}

export function formatDateToDDMMYY(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
}

export function formatDateToYYYYMMDD(date) {
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


export const isValidInput = (inputValue) => {
    const parseSting = String(inputValue);
    const rawValue = parseFloat(inputValue);
    const decimalCount = (parseSting.split(".")[1] || "").length;

    return (
      !isNaN(rawValue) && (decimalCount <= 3 || Number.isInteger(rawValue))
    );
  };