import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL, decimalplaces } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import DefaultDropdown from "../../components/DefaultDropdown";
import PasswordInput from "../../components/PasswordInput";
import NotFound from "../NotFound";
import { isValidInput } from "../../utils/common";

export default function EditCurrencyExchange() {
  const { handleInputChange, error, formData, setFormData, submitForm } =
    useForm();
  const history = useHistory();
  const params = useParams();
  const { setLoading } = useAuth();
  const formatMessage = useFormatMessage();
  const [xData, setxData] = useState({});
  const [errorBase, setErrorBase] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValidInput(formData.base_unit)) {
      setErrorBase("Input value should be three decimal");
      return;
    }
    setLoading(true);
    const data = {
      ...formData,
      status: "ACTIVE",
    };
    submitForm("PUT", "/currencyExchange/" + params.id, data, () => {
      history.push("/dashboard/currencyExchange");
    });
  };

  useEffect(() => {
    const url = BACKEND_URL + "/currencyExchange?cx_rate_id=" + params.id;
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const temp = response.data.data[0];
        console.log(temp);
        setxData(temp);
        setFormData({
          base_currency_id: temp.base_currency_id,
          base_unit: temp.base_unit,
          converted_currency_id: temp.converted_currency_id,
          converted_unit: temp.converted_unit,
          exchange_date: temp.exchange_date,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);

  if (!xData) {
    return <NotFound />;
  }

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/currencyExchange">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">
          {formatMessage("common.currencyExchange")}
        </h1>
        <div className="form-container">
          <form
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.baseCurrency")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.base_currency_id || ""}
                  required
                  entity="currency"
                  id="currency_id"
                  title="currency_name"
                  name="base_currency_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.baseAmount")}
                </label>
                <input
                  required
                  type="number"
                  name="base_unit"
                  value={formData.base_unit || ""}
                  step={decimalplaces}
                  onChange={handleInputChange}
                  className="x-input"
                />
                {errorBase && (
                  <div className="text-red-500 mt-2">{errorBase}</div>
                )}
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.convertedCurrency")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.converted_currency_id || ""}
                  required
                  entity="currency"
                  id="currency_id"
                  title="currency_name"
                  name="converted_currency_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.convertedAmount")}
                </label>
                <input
                  required
                  type="number"
                  name="converted_unit"
                  value={formData.converted_unit || ""}
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.exchangeDate")}
                </label>
                <input
                  required
                  type="date"
                  name="exchange_date"
                  value={
                    moment(formData.exchange_date).format("yyyy-MM-DD") || ""
                  }
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>

            <div>
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/currencyExchange`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.finish")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
