import React, { useEffect, useState } from "react";
import "../../css/common.css";
import { Link, useHistory } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";

function Report() {
    const formatMessage = useFormatMessage();
    let reportArray = [
        {
            title: formatMessage("common.officeUserReport"),
            link: "/dashboard/report/officeUser",
            group: "operation"
        },
        {
            title: formatMessage("common.merchantDataReport"),
            link: "/dashboard/report/merchantData",
            group: "sale&purchase"
        },
        {
            title: formatMessage("common.purchaseOrderReport"),
            link: "/dashboard/report/purchaseOrder",
            group: "sale&purchase"
        },
        {
            title: formatMessage("common.saleOrderReport"),
            link: "/dashboard/report/saleOrder",
            group: "sale&purchase"
        },
        {
            title: formatMessage("common.bankDepositTransactionReport"),
            link: "/dashboard/report/bankDepositTransaction",
            group: "transaction"
        },
        {
            title: formatMessage("common.bankWithdrawalTransactionReport"),
            link: "/dashboard/report/bankWithdrawalTransaction",
            group: "transaction"
        },
        {
            title: formatMessage("common.currencyExchangeRateReport"),
            link: "/dashboard/report/currencyExchangeRate",
            group: "transaction"
        },
        // {
        //     title: formatMessage("common.receivableReportByDateRange"),
        //     link: "/dashboard/report/receivableReportByDateRange",
        // },
        {
            title: formatMessage("common.receivableReportByYear"),
            link: "/dashboard/report/receivableReportByYear",
            group: "finance"
        },
        // {
        //     title: formatMessage("common.payableReportByDateRange"),
        //     link: "/dashboard/report/payableReportByDateRange",
        // },
        {
            title: formatMessage("common.payableReportByYear"),
            link: "/dashboard/report/payableReportByYear",
            group: "finance"
        },
        {
            title: formatMessage("common.profitAndLossReport"),
            link: "/dashboard/report/profitAndLoss",
            group: "finance"
        },
        {
            title: formatMessage("common.incomeReport"),
            link: "/dashboard/report/income",
            group: "finance"
        },
        {
            title: formatMessage("common.expenseReport"),
            link: "/dashboard/report/expense",
            group: "finance"
        },
        {
            title: formatMessage("common.otherIncomeReport"),
            link: "/dashboard/report/otherIncome",
            group: "finance"
        },
        {
            title: formatMessage("common.balanceSheet"),
            link: "/dashboard/report/balanceSheet",
            group: "finance"
        },
        {
            title: formatMessage("common.coaTransaction"),
            link: "/dashboard/report/coaTransaction",
            group: "finance"
        }
    ];

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>{formatMessage("common.report")}</h1>
            </div>

            <h4 className="mt-8 text-lg">Operation Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'operation').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>

            {/* <h4 className="mt-8 text-lg">Merchant Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'merchant').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul> */}

            <h4 className="mt-8 text-lg">Sale and Purchase Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'sale&purchase').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>


            <h4 className="mt-8 text-lg">Bank Transaction Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'transaction').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>

            {/* <h4 className="mt-8 text-lg">Receivable and Payable Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'receivable&payable').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul> */}

            <h4 className="mt-8 text-lg">Finance Reports</h4>
            <ul className="mt-3 ml-6">
                {reportArray.filter(d => d.group == 'finance').map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul>


            {/* <ul className="mt-3 ml-6">
                {reportArray.map((item, i) => (
                    <ReportItem item={item} key={i} />
                ))}
            </ul> */}
        </div>
    );
}

const ReportItem = ({ item }) => {
    return (
        <li className="mb-5">
            <Link className="text-xl underline" to={item.link}>{item.title}</Link>
        </li>
    );
};

export default Report;
