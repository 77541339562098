import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";

export default function CreateBank() {
    const { handleInputChange, formData, submitForm, error } = useForm();
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        submitForm("POST", "/bank", data, () => {
            history.push("/dashboard/bank");
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/bank">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createBank")}
                </h1>
                <div className="form-container">
                    <form
                        autoComplete="off"
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.bankName")}
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="bank_name"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label ">
                                    {formatMessage("common.remark")}
                                </label>
                                <input
                                    
                                    type="text"
                                    name="remark"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/bank`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
