import React, { useEffect, useState, useRef } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL, GeneralStatus } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashCanIcon from "../../img/icon-trash.png";
import detailIcon from "../../img/icon-details.png";
import crossIcon from "../../img/icon-cross.png";
import reloadIcon from "../../img/icon-reload.png";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { useAuth } from "../../context/AuthProvider";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";
import moment from "moment";
import DatePickerPopUp from "../../components/DatePickerPopUp";
import "react-datepicker/dist/react-datepicker.css";

export default function PurchaseOrder() {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const history = useHistory();
  const { setPopup } = useAuth();
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const [isDateFilter, setIsDateFilter] = useState(false);

  const { data, setCurrentPage, setQuery, reload } = useFetchData({
    url: BACKEND_URL + "/purchaseOrder",
    defaultQuery: "",
  });
  const formatMessage = useFormatMessage();

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  const onSearchChange = (e) => {
    const text = e.target.value;
    setQuery({
      purchase_order: text.trim(),
    });
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id
      ? e.target.parentNode.id
      : e.target.parentNode.parentNode.id;
    setPopup(
      <DeleteConfirmPopup id={id} reload={reload} entity="purchaseOrder" />
    );
  };

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
      }
      openedEl.classList.remove("open");
      setisOptionOpen(false);
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  const applyFilter = (e) => {
    const startDate = startDateRef?.current.value ?? "";
    const endDate = endDateRef?.current.value ?? "";

    e.preventDefault();
    setQuery({
      start_date: startDate,
      end_date: endDate,
    });
    setStartDate(startDate);
    setEndDate(endDate);
    setIsDateFilter(true);
    setPopup(null);
  };
  return (
    <div className="page-layout">
      <div className="page-header">
        <h1>{formatMessage("common.purchaseOrderList")}</h1>

        <button
          className="create-button mr-1"
          onClick={() =>
            setPopup(
              <DatePickerPopUp
                startDateRef={startDateRef}
                endDateRef={endDateRef}
                applyFilter={applyFilter}
              />
            )
          }
        >
          Filter
        </button>
        <div className="search-bar">
          <img src={searchIcon} />
          <input className="x-input" type="search" onChange={onSearchChange} />
        </div>
        <Link to="/dashboard/purchaseOrder/create" className="create-button">
          {formatMessage("common.createNew")}
        </Link>
      </div>

      {isDateFilter ? (
        <div className="flex  gap-2 mt-1">
          <p className="font-medium text-lg">From : {startDate}</p>
          <span className="text-lg font-bold">,</span>
          <p className="font-medium text-lg">To : {endDate}</p>
        </div>
      ) : null}

      <div className="table-container">
        <table className="x-table header-gray">
          <thead>
            <tr>
              <th>{formatMessage("common.purchaseOrderId")}</th>
              <th>{formatMessage("common.purchaseDate")}</th>
              <th>{formatMessage("common.currencyRate")}</th>
              <th>{formatMessage("common.estimateArrivalTime")}</th>
              <th>{formatMessage("common.totalAmount")}</th>
              <th className="button-col"></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((item, i) => {
              const index = (data.page - 1) * data.limit + i + 1;
              return (
                <tr key={i}>
                  <td>{item.purchase_order}</td>
                  <td>{moment(item.purchase_date).format("DD/MM/YYYY")}</td>
                  <td>{item.cxRate?.base_unit}</td>
                  <td>
                    {moment(item.estimated_arrival_date).format("DD/MM/YYYY")}
                  </td>
                  <td>{Math.round(item.total_amount * 1000) / 1000}</td>
                  <td className="button-col">
                    <div className="flex items-center">
                      <a
                        href={
                          item.status == "Bill"
                            ? "/dashboard/bill/create/" + item.purchase_order_id
                            : ""
                        }
                        className={
                          item.status == "Bill"
                            ? "flex mr-7 items-center cursor-pointer justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-green-100 text-green-800"
                            : "flex mr-7 items-center justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-gray-100"
                        }
                      >
                        {item.status}
                      </a>
                      <img
                        src={detailIcon}
                        className="col-button"
                        onClick={() => setPopup(<DetailPopup data={item} />)}
                      />
                      <img
                        className="col-button"
                        src={dotsIcon}
                        onClick={() => toggleOptions(item.purchase_order_id)}
                      />
                    </div>
                    <div className="popup-options" id={item.purchase_order_id}>
                      <Link
                        to={`${currentPath}/${item.purchase_order_id}/edit`}
                      >
                        <img src={editIcon} />
                        {formatMessage("common.edit")}
                      </Link>
                      <a href="" onClick={onDeleteClick}>
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

const DetailPopup = ({ data }) => {
  const formatMessage = useFormatMessage();
  const { setPopup } = useAuth();

  return (
    <div
      className="popup relative"
      style={{ maxWidth: "1080px", width: "100%" }}
    >
      <h4 className="mb-10">
        {formatMessage("common.purchaseOrder")}
        {formatMessage("common.information")}
      </h4>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.purchaseOrderId")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.purchase_order}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.currencyRate")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.cxRate.base_unit}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.purchaseDate")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">
          {moment(data.purchase_date).format("DD/MM/YYYY")}
        </span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.remark")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.remark}</span>
      </div>

      <div className="w-full border-t border-t-gray-500">
        <h3 className="sub-title mb-4 mt-3">
          {formatMessage("common.productList")}
        </h3>
        <table className="popup-table">
          <thead>
            <tr>
              <td>{formatMessage("common.productCategory")}</td>
              <td>{formatMessage("common.productName")}</td>
              <td>{formatMessage("common.quantity")}</td>
              <td>{formatMessage("common.unit")}</td>
              <td>{formatMessage("common.remark")}</td>
            </tr>
          </thead>
          <tbody>
            {data.purchaseOrderDetail.map((d, i) => (
              <tr key={i}>
                <td>{d.productCategory.product_category_name}</td>
                <td>{d.product_name}</td>
                <td>x{d.quantity}</td>
                <td>{d.unit_id ? d.unit.unit_name : ""}</td>
                <td>{d.remark}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        src={crossIcon}
        onClick={() => setPopup(null)}
      />
    </div>
  );
};
