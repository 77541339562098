import axios from "axios";
import { useState } from "react";
import { BACKEND_URL } from "../utils/constant";
import { useAuth } from "../context/AuthProvider";


export const useForm = (initData = {}) => {

    const {setLoading} = useAuth();
	const [formData, setFormData] = useState(initData);
    const [error, setError] = useState('');

	const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const fieldChange = (field) => {
        return value => {
            setFormData(old => ({
                ...old,
                [field]: value
            }))
        }
    }


	const submitForm = async (method, endpoint, data, callback) => {
        console.log('form request - ', {
            url: endpoint,
            method,
            body: data
        })
        try {
            const response = await axios.request({
                method,
                url: `${BACKEND_URL}${endpoint}`,
                data,
                withCredentials: true
            })
            // setLoading(false)
            console.log('Form submitted', response)
            callback(response)

        }
        catch(e) {
            console.log(e)
            // setLoading(false)
            if (e.response && e.response.data && e.response.data.message && e.response.status != 500)
            {
                if (e.response.data && e.response.data.key === 'unique violation')
                {
                    setError('Duplicated Field!')
                }
                else {
                    setError(e.response.data.message)
                }

            }
            else {
                console.log('Server Error!', e.response)
                setError('Something went wrong!')
            }
        };
	}

    return {
        handleInputChange,
        fieldChange,
        formData, 
        setFormData,
        submitForm,
        setError,
        error, 
    }

}

