import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import DashboardTemplate from '../components/DashboardTemplate';
import NotFound from '../pages/NotFound';
import { allow } from '../utils/common';
import { useAuth } from './AuthProvider'

function PrivateRoute({component:Component, module = '', ...rest}) {
  const {user} = useAuth();
  if (module !== 'dashboard' && (user && !allow(user.userAccess, module)))
  {
    return <></>
  }
  
  return (
    <Route {...rest} render={(props) => user ? 
        (
          <DashboardTemplate>
            <Component/>
          </DashboardTemplate>
        )
        : 
        <Redirect to='/'/>
    }>

    </Route>
  )
}

export default PrivateRoute