import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import SaleProductForm from "../../components/SaleProductForm";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BACKEND_URL } from "../../utils/constant";
import PurchaseProductForm from "../../components/PurchaseProductForm";
import DefaultDropdown from "../../components/DefaultDropdown";
import DataDropdown from "../../components/DataDropdown";

export default function EditPurchaseOrder() {
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const params = useParams();
  const [currency, setcurrency] = useState();
  const [cxRate, setCxRate] = useState();
  const { user, setLoading } = useAuth();
  const {
    state: { routerPayload } = {
      routerPayload: {
        items: [],
      },
    },
  } = useLocation();
  console.log({ routerPayload });
  const { handleInputChange, formData, setFormData, submitForm, error } =
    useForm({
      ...routerPayload,
    });

  useEffect(() => {
    if (formData.purchase_order_id) {
      return;
    }
    const url =
      BACKEND_URL +
      "/purchaseOrder?included=true&purchase_order_id=" +
      params.id;
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const temp = response.data.data[0];
        console.log(temp);
        setFormData({
          ...temp,
          currency_id: temp.cxRate.converted_currency_id,
          items: temp.purchaseOrderDetail,
        });
      })
      .catch((e) => {
        console.log({ e });
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let total_amount = formData.items.reduce((v, d, i) => {
      return v + d.purchase_price_mmk * d.quantity;
    }, 0);

    const data = {
      ...formData,
      total_amount,
      status: "Bill",
    };
    submitForm("PUT", "/purchaseOrder/" + params.id, data, () => {
      history.push("/dashboard/purchaseOrder");
    });
  };

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/purchaseOrder">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">
          {formatMessage("common.editPurchaseOrder")}
        </h1>
        <h2 className="mt-6" style={{ fontSize: "22px" }}>
          {formatMessage("common.purchaseOrderInformation")}
        </h2>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchaseOrderId")}
                </label>
                <input
                  required
                  disabled
                  type="text"
                  value={formData.purchase_order || ""}
                  name="purchase_order"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>

              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.supplierName")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.supplier_id}
                  required
                  entity="supplier"
                  id="supplier_id"
                  title="supplier_name"
                  name="supplier_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchaseDate")}
                </label>
                <input
                  required
                  type="date"
                  defaultValue={formData.purchase_date}
                  name="purchase_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>

              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.currency")}
                </label>
                <DefaultDropdown
                  required
                  extQuery={`&base_currency_flag=NO`}
                  value={formData.currency_id}
                  entity="currency"
                  setData={setcurrency}
                  name="currency_id"
                  onChange={handleInputChange}
                  title="currency_name"
                  id="currency_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.currencyExchange")}
                </label>
                <DefaultDropdown
                  required
                  setData={setCxRate}
                  extQuery={`&exchange_date=${formData.purchase_date}&converted_currency_id=${formData.currency_id}`}
                  value={formData.cx_rate_id}
                  entity="currencyExchange"
                  name="cx_rate_id"
                  onChange={handleInputChange}
                  title="base_unit"
                  id="cx_rate_id"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.estimateArrivalTime")}
                </label>
                <input
                  type="date"
                  defaultValue={
                    formData.estimated_arrival_date
                      ? moment(formData.estimated_arrival_date).format(
                          "YYYY-MM-DD"
                        )
                      : ""
                  }
                  name="estimated_arrival_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  value={formData.remark || ""}
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>
            <PurchaseProductForm
              setFormdata={setFormData}
              currency={currency}
              formData={formData}
              cxRate={cxRate}
            />

            <div className="mt-5">
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/purchaseOrder`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.update")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
