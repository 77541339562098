import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DefaultDropdown from "../../components/DefaultDropdown";
import { decimalplaces } from "../../utils/constant";

export default function AddSaleProduct() {
  const formatMessage = useFormatMessage();

  const [productCategory, setProductCategory] = useState();
  const [unit, setUnit] = useState();

  const history = useHistory();
  const { state } = useLocation();
  let prevData = {
    items: [],
  };
  if (state && state.routerPayload) {
    prevData = state.routerPayload;
  }
  const redirect_url = prevData.sale_order_id
    ? `/dashboard/saleOrder/${prevData.sale_order_id}/edit`
    : "/dashboard/saleOrder/create";
  const [formData, setFormData] = useState({
    sale_order_id: prevData.sale_order_id,
  });
  const handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let newData = {
      [name]: value.trim(),
    };

    if (name == "product_category_id") {
      let temp = productCategory.filter((d) => d.product_category_id == value);
      newData.product_category_name = temp[0]
        ? temp[0].product_category_name
        : "";
    }

    if (name == "unit_id") {
      let temp = unit.filter((d) => d.unit_id == value);
      newData.unit_name = temp[0] ? temp[0].unit_name : "";
    }
    setFormData({
      ...formData,
      ...newData,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(prevData);
    prevData.items.push({ ...formData, id: prevData.items.length + 1 });
    history.push(redirect_url, {
      routerPayload: prevData,
    });
  };

  const backClick = (e) => {
    e.preventDefault();
    history.push(redirect_url, {
      routerPayload: prevData,
    });
  };

  return (
    <div className="sub-page-layout-1">
      <a className="back-to-page" onClick={backClick}>
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </a>
      <div className="container flex flex-col">
        <div>
          <h1 className="sub-page-title">
            {formatMessage("common.createSaleOrder")}
          </h1>

          <h4 className="text-xl mt-5">
            {formatMessage("common.addNewProduct")}
          </h4>
        </div>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productCategory")}
                </label>
                <DefaultDropdown
                  required
                  setData={setProductCategory}
                  value={formData.product_category_id || ""}
                  name="product_category_id"
                  onChange={handleInputChange}
                  entity="productCategory"
                  id="product_category_id"
                  title="product_category_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productName")}
                </label>
                <input
                  required
                  type="text"
                  name="product_name"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.quantity")}
                </label>
                <input
                  required
                  type="number"
                  step={decimalplaces}
                  name="product_qty"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.unit")}
                </label>
                <DefaultDropdown
                  required
                  setData={setUnit}
                  value={formData.unit_id || ""}
                  name="unit_id"
                  onChange={handleInputChange}
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.saleType")}
                </label>
                <select
                  required
                  name="sale_type_id"
                  onChange={handleInputChange}
                  className="x-input"
                >
                  <option value=""></option>
                  <option value="Prepaid">Prepaid</option>
                  <option value="Postpaid">Postpaid</option>
                </select>
              </div>

              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>

            <div>
              <div className="flex justify-end gap-5">
                <a href="" onClick={backClick} className="white-btn">
                  {formatMessage("common.cancel")}
                </a>
                <button className="black-btn">
                  {formatMessage("common.create")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
