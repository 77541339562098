import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { formatCurrency, isValidInput } from "../utils/common";
import { useEffect, useLayoutEffect, useState } from "react";
import dotsIcon from "../img/icondots.png";
import editIcon from "../img/icon-edit.png";
import trashCanIcon from "../img/icon-trash.png";
import crossIcon from "../img/icon-cross.png";
import { useAuth } from "../context/AuthProvider";
import DefaultDropdown from "./DefaultDropdown";
import { BACKEND_URL, decimalplaces } from "../utils/constant";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";

export default function PurchaseProductForm({
  formData,
  currency,
  currName = "",
  cxRate,
  setFormdata = () => {},
  isInfo = false,
}) {
  const formatMessage = useFormatMessage();
  const history = useHistory();

  const [cname, setcname] = useState();
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const { setPopup } = useAuth();
  const location = useLocation();

  const addProduct = (e) => {
    e.preventDefault();
    console.log(location);
    // console.log({ formData });
    history.push("/dashboard/purchaseOrder/create/addProduct", {
      routerPayload: { ...formData, currency },
    });
  };

  useEffect(() => {
    if (location.state && location.state.routerPayload.items) {
      setFormdata({
        ...formData,
        items: location.state.routerPayload.items,
      });
    }
  }, [location.state]);

  const handleRemove = (id) => {
    const filterItems = formData?.items.filter((_, index) => index != id);
    history.replace({
      ...location,
      state: {
        ...location.state,
        routerPayload: {
          items: filterItems,
        },
      },
    });
  };

  const toggleOptions = (id) => {
    console.log(id);
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  useLayoutEffect(() => {
    if (currency && formData.currency_id) {
      let currencyName = currency.filter(
        (d) => d.currency_id === formData.currency_id
      )[0].currency_name;
      setcname(currencyName);
    }
  }, [currency, formData.currency_id]);

  useEffect(() => {
    if (currName) {
      setcname(currName);
    }
  }, [currName]);

  useEffect(() => {
    if (formData.cx_rate_id && cxRate) {
      let xtemp = [...formData.items];
      xtemp = xtemp.map((v) => {
        let xprice = v.purchase_price_yun;
        let currentCx = cxRate.filter(
          (cx) => cx.cx_rate_id == formData.cx_rate_id
        )[0];
        let rate = currentCx.base_unit / currentCx.converted_unit;

        v.purchase_price_mmk = xprice * rate;
        return v;
      });

      setFormdata({
        ...formData,
        items: xtemp,
      });
    }
  }, [formData.cx_rate_id]);

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
      }
      openedEl.classList.remove("open");
      setisOptionOpen(false);
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  return (
    <div className="flex flex-col items-end mt-6">
      {!isInfo && (
        <a onClick={addProduct} className="black-btn whitespace-nowrap">
          + {formatMessage("common.addProduct")}
        </a>
      )}

      <table className="x-table mt-6 x-table-center">
        <thead>
          <tr>
            <td>{formatMessage("common.productCategory")}</td>
            <td>{formatMessage("common.productName")}</td>
            <td>{formatMessage("common.quantity")}</td>
            <td>{formatMessage("common.unit")}</td>
            <td>
              {cname
                ? formatMessage("common.purchasePrice") + cname
                : formatMessage("common.purchasePrice")}
            </td>
            <td>{formatMessage("common.purchasePriceMMK")}</td>
            <td className="button-col"></td>
          </tr>
        </thead>
        <tbody>
          {formData.items &&
            formData.items.map((item, index) => {
              return (
                <tr
                  key={index}
                  className=""
                  style={{
                    paddingTop: "25px",
                    paddingBottom: "25px",
                  }}
                >
                  <td>
                    <span>
                      {item.product_category_name
                        ? item.product_category_name
                        : item.productCategory.product_category_name}
                    </span>
                  </td>
                  <td>
                    <span>{item.product_name}</span>
                  </td>
                  <td>
                    <span>{item.quantity}</span>
                  </td>
                  <td>
                    <span>{item.unit_name ?? item.unit?.unit_name ?? ""}</span>
                  </td>
                  <td>
                    <span>{item.purchase_price_yun}</span>
                  </td>
                  <td>
                    <span>{item.purchase_price_mmk}</span>
                  </td>
                  <td className="button-col">
                    <div className="flex">
                      <img
                        alt=""
                        className="col-button"
                        src={dotsIcon}
                        onClick={() => toggleOptions(index)}
                      />
                    </div>
                    <div className="popup-options" id={index}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setPopup(
                            <DetailPopup
                              data={{ ...item, id: index }}
                              routerPayload={formData}
                              history={history}
                            />
                          );
                        }}
                      >
                        <img src={editIcon} />
                        <span>{formatMessage("common.edit")}</span>
                      </a>

                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemove(index);
                        }}
                      >
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

const DetailPopup = ({ data, routerPayload, history }) => {
  const { items, cx_rate_id } = routerPayload;
  const [productCategory, setProductCategory] = useState();
  const [unit, setUnit] = useState();
  const [formData, setFormData] = useState({ ...data });
  const [errorYun, setErrorYun] = useState("");
  const [errorMmk, setErrorMmk] = useState("");
  const [errorQuantity, setErrorQuantity] = useState("");
  const [cxRate, setCxRate] = useState(null);
  const formatMessage = useFormatMessage();
  const { setPopup } = useAuth();
  const updateElementById = (id, updatedData) => {
    return items.map((item, index) =>
      index === id ? { ...item, ...updatedData } : item
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const errorMessage = "Input value should be three decimal";
    if (!isValidInput(formData.purchase_price_yun)) {
      setErrorYun(errorMessage);
      return;
    }
    if (!isValidInput(formData.purchase_price_mmk)) {
      setErrorMmk(errorMessage);
      return;
    }
    if (!isValidInput(formData.quantity)) {
      setErrorMmk(errorMessage);
      return;
    }

    setErrorYun("");
    setErrorMmk("");
    setErrorQuantity("");
    let updateArray = updateElementById(formData?.id, formData);
    const currentLocation = history.location;
    console.log(updateArray);
    history.replace({
      ...currentLocation,
      state: {
        ...currentLocation.state,
        routerPayload: {
          items: updateArray,
        },
      },
    });
    setPopup(null);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    let newData = {
      [name]: value.trim(),
    };

    if (name == "product_category_id") {
      let temp = productCategory.filter((d) => d.product_category_id == value);
      newData.product_category_name = temp[0]
        ? temp[0].product_category_name
        : "";
    }

    if (name == "unit_id") {
      let temp = unit.filter((d) => d.unit_id == value);
      newData.unit_name = temp[0] ? temp[0].unit_name : "";
    }

    setFormData({ ...formData, ...newData });
  };

  useEffect(() => {
    if (cx_rate_id) {
      let url =
        BACKEND_URL +
        "/currencyExchange?status=ACTIVE&included=true&&cx_rate_id=" +
        cx_rate_id;
      axios
        .get(url, {
          withCredentials: true,
        })
        .then((response) => {
          let temp = response.data.data[0];

          setCxRate({ ...temp });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const cnyInputKeyUp = (e) => {
    if (cxRate) {
      let cnyPrice = e.target.value;
      let rate = cxRate.base_unit / cxRate.converted_unit;
      let mmkPrice = cnyPrice * rate;
      setFormData({
        ...formData,
        purchase_price_mmk: Math.round(mmkPrice * 1000) / 1000,
      });
    }
  };

  const mmkInputKeyUp = (e) => {
    if (cxRate) {
      let mmkPrice = e.target.value;
      let rate = cxRate.converted_unit / cxRate.base_unit;
      let cnyPrice = mmkPrice * rate;
      setFormData({
        ...formData,
        purchase_price_yun: Math.round(cnyPrice * 1000) / 1000,
      });
    }
  };
  return (
    <div
      className="popup relative"
      style={{ maxWidth: "1080px", width: "100%" }}
    >
      <div className="container flex flex-col">
        <div className="mb-10">
          <h1 className="sub-page-title">
            {formatMessage("common.editPurchaseOrder")}
          </h1>
        </div>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productCategory")}
                </label>
                <DefaultDropdown
                  required
                  setData={setProductCategory}
                  value={formData?.product_category_id ?? ""}
                  name="product_category_id"
                  onChange={handleInputChange}
                  entity="productCategory"
                  id="product_category_id"
                  title="product_category_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.productName")}
                </label>
                <input
                  required
                  type="text"
                  name="product_name"
                  value={formData?.product_name ?? ""}
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.quantity")}
                </label>
                <input
                  required
                  type="number"
                  step={decimalplaces}
                  name="quantity"
                  onChange={handleInputChange}
                  value={formData?.quantity ?? ""}
                  className="x-input"
                />
                {errorQuantity && (
                  <div className="text-red-500 mt-2">{errorQuantity}</div>
                )}
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.unit")}
                </label>
                <DefaultDropdown
                  required
                  setData={setUnit}
                  value={formData?.unit_id ?? ""}
                  name="unit_id"
                  onChange={handleInputChange}
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchasePrice") +
                    (cxRate != null
                      ? cxRate.convertedCurrency.currency_name
                      : "")}
                </label>
                <input
                  required
                  type="number"
                  name="purchase_price_yun"
                  step={decimalplaces}
                  value={formData?.purchase_price_yun ?? ""}
                  onKeyUp={cnyInputKeyUp}
                  onChange={handleInputChange}
                  className="x-input"
                />
                {errorYun && (
                  <div className="text-red-500 mt-2">{errorYun}</div>
                )}
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchasePriceMMK")}
                </label>
                <input
                  required
                  type="number"
                  step={decimalplaces}
                  name="purchase_price_mmk"
                  value={formData?.purchase_price_mmk ?? ""}
                  onChange={handleInputChange}
                  onKeyUp={mmkInputKeyUp}
                  className="x-input"
                />
                {errorMmk && (
                  <div className="text-red-500 mt-2">{errorMmk}</div>
                )}
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                  value={formData?.remark ?? ""}
                />
              </div>
            </div>

            <div>
              <div className="flex justify-end gap-5 mt-10">
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    setPopup(null);
                  }}
                  className="white-btn"
                >
                  {formatMessage("common.cancel")}
                </a>
                <button className="black-btn">
                  {formatMessage("common.update")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        onClick={() => setPopup(null)}
        src={crossIcon}
        alt=""
      />
    </div>
  );
};
