import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import SaleProductForm from "../../components/SaleProductForm";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BACKEND_URL } from "../../utils/constant";
import PurchaseProductForm from "../../components/PurchaseProductForm";
import DefaultDropdown from "../../components/DefaultDropdown";

export default function CreateBill() {
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const [currency, setcurrency] = useState();
  const { user, setLoading } = useAuth();
  const { purchase_order_id } = useParams();
  const { handleInputChange, formData, setFormData, submitForm, error } =
    useForm();

  useEffect(() => {
    (async () => {
      let url = BACKEND_URL + "/common/generateID";
      let response = await axios.post(
        url,
        { name: "bill" },
        {
          withCredentials: true,
        }
      );
      let bill_number = response.data.data;

      url =
        BACKEND_URL +
        "/purchaseOrder?included=true&purchase_order_id=" +
        purchase_order_id;
      response = await axios.get(url, {
        withCredentials: true,
      });
      let tempData = response.data.data[0];
      setcurrency(tempData.cxRate.convertedCurrency.currency_name);
      setFormData({
        ...formData,
        ...tempData,
        sub_total_amount: Math.round(tempData.total_amount * 1000) / 1000,
        total_amount: Math.round(tempData.total_amount * 1000) / 1000,
        items: tempData.purchaseOrderDetail,
        other_charges: 0,
        transportation_charges: 0,
        bill_number,
      });
      console.log(tempData);
    })();
  }, []);

  useEffect(() => {
    let sub_total_amount =
      Math.round(formData.sub_total_amount * 1000) / 1000 || 0;
    let other_charges = Math.round(formData.other_charges * 1000) / 1000 || 0;
    let transportation_charges =
      Math.round(formData.transportation_charges * 1000) / 1000 || 0;
    let total_amount =
      sub_total_amount + other_charges + transportation_charges;
    setFormData({
      ...formData,
      total_amount: Math.round(total_amount * 1000) / 1000,
    });
  }, [
    formData.sub_total_amount,
    formData.other_charges,
    formData.transportation_charges,
  ]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      ...formData,
      status: "Pay",
    };
    console.log("form data - ", data);
    submitForm("POST", "/bill/cascadeCreate", data, () => {
      history.push("/dashboard/bill");
    });
  };

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/bill">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">{formatMessage("common.createBill")}</h1>
        <h2 className="mt-6" style={{ fontSize: "22px" }}>
          {formatMessage("common.billInformation")}
        </h2>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.billNumber")}
                </label>
                <input
                  disabled
                  required
                  type="text"
                  value={formData.bill_number || ""}
                  name="bill_number"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchaseDate")}
                </label>
                <input
                  required
                  disabled
                  type="date"
                  value={
                    formData.purchase_date
                      ? moment(formData.purchase_date).format("YYYY-MM-DD")
                      : ""
                  }
                  name="purchase_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.estimateArrivalTime")}
                </label>
                <input
                  type="date"
                  disabled
                  value={
                    formData.estimated_arrival_date
                      ? moment(formData.estimated_arrival_date).format(
                          "YYYY-MM-DD"
                        )
                      : ""
                  }
                  name="estimated_arrival_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.billDate")}
                </label>
                <input
                  type="date"
                  defaultValue={
                    moment(formData.bill_date).format("YYYY-MM-DD") || ""
                  }
                  name="bill_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.dueDate")}
                </label>
                <input
                  type="date"
                  defaultValue={
                    moment(formData.due_date).format("YYYY-MM-DD") || ""
                  }
                  name="due_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  value={formData.remark || ""}
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>
            <PurchaseProductForm
              isInfo={true}
              formData={formData}
              currName={currency}
              setFormdata={setFormData}
            />

            <div className="mt-6 gap-3 mb-8 flex flex-col items-end mr-6">
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.subtotal")}
                </span>
                <span
                  type="number"
                  className="text-center"
                  style={{ width: "155px" }}
                >
                  {formData.sub_total_amount || 0}
                </span>
              </div>
              <div className="flex w-96">
                <span className="flex-grow flex items-center">
                  {formatMessage("common.otherCharges")}
                </span>
                <input
                  type="number"
                  name="other_charges"
                  onChange={handleInputChange}
                  className="x-input text-center"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="flex w-96">
                <span className="flex-grow flex items-center">
                  {formatMessage("common.transportationCharges")}
                </span>
                <input
                  type="number"
                  name="transportation_charges"
                  onChange={handleInputChange}
                  className="x-input text-center"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.total")}
                </span>
                <span
                  type="number"
                  className="text-center"
                  style={{ width: "155px" }}
                >
                  {formData.total_amount || 0}
                </span>
              </div>
            </div>

            <div className="mt-5">
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/bill`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.create")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
