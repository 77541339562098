import React from "react";
import homeImg from "../img/icon-home.png";
import officeUserImg from "../img/icon_office_user.png";
import purchaseImg from "../img/icon_purchase.png";
import merchantImg from "../img/icon-customer.png";
import saleImg from "../img/icon_sale.png";
import bankImg from "../img/icon_bank.png";
import exchangeImg from "../img/icon_exchange.png";
import financeImg from "../img/finance_logo.png";
import reportImg from "../img/icon-report.png";
import downArrow from "../img/icon-downarrow.png";
import { Link, useHistory } from "react-router-dom";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { allow } from "../utils/common";
import { useAuth } from "../context/AuthProvider";

function Navigation() {
  const formatMessage = useFormatMessage();
  const purchaseSubNavItems = [
    {
      text: formatMessage("nav.purchaseOrder"),
      link: "/dashboard/purchaseOrder",
      module: "Purchase Order Management",
    },
    {
      text: formatMessage("nav.bill"),
      link: "/dashboard/bill",
      module: "Purchase Order Management",
    },
    {
      text: formatMessage("nav.paymentMake"),
      link: "/dashboard/paymentMake",
      module: "Purchase Order Management",
    },
  ];

  const saleSubNavItems = [
    {
      text: formatMessage("nav.saleOrder"),
      link: "/dashboard/saleOrder",
      module: "Sales Order Management",
    },
    {
      text: formatMessage("nav.invoice"),
      link: "/dashboard/invoice",
      module: "Sales Order Management",
    },
    {
      text: formatMessage("nav.paymentReceive"),
      link: "/dashboard/paymentReceive",
      module: "Sales Order Management",
    },
  ];

  const bankSubNavItems = [
    {
      text: formatMessage("common.bank"),
      link: "/dashboard/bank",
      module: "Bank Management",
    },
    {
      text: formatMessage("common.bankAccount"),
      link: "/dashboard/bankAccount",
      module: "Bank Management",
    },
    {
      text: formatMessage("common.bankTransaction"),
      link: "/dashboard/bankTransaction",
      module: "Bank Transaction Management",
    },
  ];

  const currencyExSubNavItems = [
    {
      text: formatMessage("common.currency"),
      link: "/dashboard/currency",
      module: "Currency Management",
    },
    {
      text: formatMessage("common.currencyExchange"),
      link: "/dashboard/currencyExchange",
      module: "Currency Management",
    },
  ];
  const financeSubNavItems = [
    {
      text: formatMessage("common.mainAccount"),
      link: "/dashboard/mainAccount",
      // module: "Reports",
      module: "Finance Management",
    },
    {
      text: formatMessage("common.subAccount"),
      link: "/dashboard/subAccount",
      // module: "Reports",
      module: "Finance Management",
    },
    {
      text: formatMessage("common.coaTransaction"),
      link: "/dashboard/coaTransaction",
      // module: "Reports",
      module: "Finance Management",
    },
  ];

  const unitsSubNavItems = [
    {
      text: formatMessage("common.unit"),
      link: "/dashboard/unit",
      // module: "Reports",
      module: "Currency Management",
    },
    {
      text: formatMessage("common.uoc"),
      link: "/dashboard/uoc",
      // module: "Reports",
      module: "Currency Management",
    },
  ];
  return (
    <div className="navigation">
      <NavItem
        icon={homeImg}
        to="/dashboard/home"
        text={formatMessage("nav.home")}
        module="dashboard"
      />
      <NavItem
        icon={officeUserImg}
        to="/dashboard/officeUser"
        text={formatMessage("nav.officeUser")}
        module="Office User Management"
      />
      <NestedNavItem
        icon={purchaseImg}
        to="/dashboard/purchaseOrder"
        text={formatMessage("nav.purchase")}
        subnav={purchaseSubNavItems}
      />

      <NavItem
        icon={officeUserImg}
        to="/dashboard/supplier"
        text={formatMessage("common.supplier")}
        module="Supplier Management"
      />

      <NavItem
        icon={merchantImg}
        to="/dashboard/merchant"
        text={formatMessage("nav.merchant")}
        module="Merchant Management"
      />

      <NavItem
        icon={saleImg}
        to="/dashboard/productCategory"
        text={formatMessage("common.productCategory")}
        module="Sales Order Management"
      />

      <NestedNavItem
        icon={saleImg}
        to="/dashboard/saleOrder"
        text={formatMessage("nav.sale")}
        subnav={saleSubNavItems}
      />

      <NestedNavItem
        icon={bankImg}
        to="/dashboard/bankTransaction"
        text={formatMessage("nav.bank")}
        subnav={bankSubNavItems}
      />

      <NestedNavItem
        icon={exchangeImg}
        to="/dashboard/currency"
        text={formatMessage("common.currencyExchange")}
        subnav={currencyExSubNavItems}
      />
      <NestedNavItem
        icon={financeImg}
        to="/dashboard/mainAccount"
        text={formatMessage("common.finance")}
        subnav={financeSubNavItems}
        module="Finance Management"
      />

      <NestedNavItem
        icon={exchangeImg}
        to="/dashboard/unit"
        text={formatMessage("nav.units")}
        subnav={unitsSubNavItems}
      />

      <NavItem
        icon={reportImg}
        to="/dashboard/report"
        text={formatMessage("nav.report")}
        module="Reports"
      />
    </div>
  );
}

const NavItem = ({ icon, text, to, module }) => {
  const history = useHistory();
  const { user } = useAuth();
  const currentPath = history.location.pathname;
  const active = currentPath.startsWith(to);

  if (module !== "dashboard" && !allow(user.userAccess, module)) {
    return <></>;
  }

  return (
    <Link to={to} className={`nav-item ${active ? "active" : ""}`}>
      <img src={icon} />
      <span>{text}</span>
    </Link>
  );
};

const NestedNavItem = ({ icon, to, text, subnav }) => {
  const { user } = useAuth();
  const history = useHistory();
  const currentPath = history.location.pathname;
  let isActive = false;
  subnav.forEach((item) => {
    if (currentPath.startsWith(item.link)) {
      isActive = true;
    }
  });

  subnav = subnav.filter((item) => allow(user.userAccess, item.module));
  if (subnav.length === 0) {
    return <></>;
  }

  return (
    <div className={`nested-nav-item`}>
      <Link to={to} className={`parent-nav ${isActive ? "active" : ""}`}>
        <img src={icon} />
        <span>{text}</span>
        <img src={downArrow} className="downarrow" />
      </Link>
      {isActive && (
        <div className="child-nav">
          {subnav.map((item, index) => {
            return (
              <Link
                key={index}
                className={`sub-nav-item ${
                  currentPath.startsWith(item.link) ? "active" : ""
                }`}
                to={item.link}
              >
                {item.text}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Navigation;
