import React from "react";
import useGetList from "../hooks/useGetList";

function DataDropdown({ id, title, data, ...rest }) {
   
    return (
        <div className="dd-container">
            <select type="text" value="" className="x-input dropdown" {...rest}>
                <option value="">Select</option>
                {data.map((item, index) => (
                    <option key={index} value={item[id]}>
                        {item[title]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default DataDropdown;
