import React, { useEffect, useState } from 'react'
import profileImg from '../img/user-profile.png'
import logoutImg from '../img/icon-logout.png'
import { useAuth } from '../context/AuthProvider'
import { useFormatMessage } from '../hooks/useFormatMessage';
import resetIcon from '../img/icon-reload.png'
import logoutIcon from '../img/icon-logout.png'
import MyResetPopup  from './MyResetPopup.js';
import LanguagePopup from './LanguagePopup';

export default function UserProfile() {
  const {logout, user, setPopup} = useAuth();
  const formatMessage = useFormatMessage();

  const onLogout = () => {
    logout();
  }



  return (
    <div className='user-profile-section'>
        <div className='user-profile-divider'></div>
        <div className='profile-layout'>
            <img className='profile-image' src={profileImg} />
            <div className='user-detail'>
                <span className='name'>{ user.name }</span>
                <span className='role'>{ user.role.role_name}</span>
            </div>
            <img className='logout-image' src={logoutImg}/>

            <div className='profile-popup'>
              <div onClick={() => { setPopup(<LanguagePopup/>) }}>
                <img src={resetIcon} />
                <span>{ formatMessage('common.changeLang') }</span>
              </div>
              <div onClick={() => { setPopup(<MyResetPopup userId={user.user_id}/>) }}>
                <img src={resetIcon} />
                <span>{ formatMessage('common.changePassword') }</span>
              </div>
              <div onClick={onLogout}>
                <img src={logoutIcon}/>
                <span>{ formatMessage('nav.logout') }</span>
              </div>
            </div>
        </div>
        
    </div>
  )
}
