import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import DefaultDropdown from "../../components/DefaultDropdown";
import PasswordInput from "../../components/PasswordInput";
import NotFound from "../NotFound";

export default function EditUnit() {
  const { handleInputChange, error, formData, setFormData, submitForm } =
    useForm();
  const history = useHistory();
  const params = useParams();
  const { setLoading } = useAuth();
  const formatMessage = useFormatMessage();
  const [xData, setxData] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...formData,
      status: "ACTIVE",
    };
    submitForm("PUT", "/unit/" + params.id, data, () => {
      history.push("/dashboard/unit");
    });
  };

  useEffect(() => {
    const url = BACKEND_URL + "/unit?unit_id=" + params.id;
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const temp = response.data.data[0];
        setxData(temp);
        setFormData({
          unit_name: temp.unit_name,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);

  if (!xData) {
    return <NotFound />;
  }

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/unit">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">{formatMessage("common.unit")}</h1>
        <div className="form-container">
          <form
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.unitName")}
                </label>
                <input
                  required
                  type="text"
                  name="unit_name"
                  value={formData.unit_name || ""}
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>

            <div>
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/unit`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.finish")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
