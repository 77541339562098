import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import DefaultDropdown from "../../components/DefaultDropdown";
import SaleProductForm from "../../components/SaleProductForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function CreateSaleOrder() {
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const { user, setLoading } = useAuth();
  const {
    state: { routerPayload } = {
      routerPayload: {
        items: [],
      },
    },
  } = useLocation();
  const { handleInputChange, formData, setFormData, submitForm, error } =
    useForm({
      ...routerPayload,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...formData,
      status: "Invoice",
    };
    submitForm("POST", "/saleOrder/cascadeCreate", data, () => {
      history.push("/dashboard/saleOrder");
    });
  };

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/saleOrder">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">
          {formatMessage("common.createSaleOrder")}
        </h1>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.merchantName")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.merchant_id}
                  required
                  entity="merchant"
                  id="merchant_id"
                  title="merchant_name"
                  name="merchant_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.saleOrderDate")}
                </label>
                <input
                  required
                  type="date"
                  defaultValue={formData.sale_order_date}
                  name="sale_order_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  value={formData.remark}
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>
            <SaleProductForm formData={formData} setFormData={setFormData} />

            <div className="mt-5">
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/saleOrder`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.create")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
