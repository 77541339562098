import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {useFormatMessage} from '../hooks/useFormatMessage'
import totalBalanceIcon from '../img/total_balance.png'
import receiveIcon from '../img/icon_down.png'
import payIcon from '../img/icon_up.png'
import lossProfitIcon from '../img/loss_profit.png'
import personIcon from '../img/person.png'
import { formatCurrency } from '../utils/common'
import { BACKEND_URL } from '../utils/constant'
import { Link } from 'react-router-dom'

export default function Dashboard() {
  const formatMessage = useFormatMessage()
  const [stats, setStats] = useState(null)

  useEffect(() => {

    axios.get(BACKEND_URL + '/stats/dashboard', { withCredentials: true })
    .then(response => {
      setStats(response.data.data)
    })
    .catch(e => {
      console.log(e.response)
    })  
  
  }, [])
  


  return (
    <div className='home-layout'>
      <h1 className='title'>{ formatMessage('common.dashboard') }</h1>
      
      <div className='amount-stats-container'>
        <div className='box'>
          <img src={totalBalanceIcon} className='icon' />
          <h4 className='label t-gray'>{ formatMessage('common.totalBalance') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.totalBalance : 0)} MMK</p>
        </div>
        <div className='box'>
          <img src={receiveIcon} className='icon' />
          <h4 className='label t-gray'>{ formatMessage('common.dailyReceivable') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.dailyReceivable : 0)} MMK</p>
        </div>
        <div className='box'>
          <img src={payIcon} className='icon' />
          <h4 className='label t-gray'>{ formatMessage('common.dailyPayable') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.dailyPayable : 0)} MMK</p>
        </div>
        <div className='box'>
          <img src={lossProfitIcon} className='icon' />
          <h4 className='label t-gray'>{ formatMessage('common.dailyLossAndProfit') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.totalAmount : 0)} MMK</p>
        </div>
        <div className='box'>
          <img src={lossProfitIcon} className='icon' />
          <h4 className='label t-gray'>{ formatMessage('common.weeklyLossAndProfit') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.totalAmount : 0)} MMK</p>
        </div>
        <div className='box'>
          <img src={lossProfitIcon} className='icon' />
          <h4 className='label t-gray'>{ formatMessage('common.monthlyLossAndProfit') }</h4>
          <p className='amount'>{ formatCurrency(stats ? stats.totalAmount : 0)} MMK</p>
        </div>
        <div className='box relative'>
          <img src={personIcon} className='icon' />
          <h4 className='label t-gray'>{ formatMessage('common.topMerchant') }</h4>
          <p className='amount'>{ 10 } </p>
          <a href='/dashboard/topTenMerchant' className='block absolute bottom-10 right-11'>{ formatMessage('common.seeAll') }</a>
        </div>
      </div>

    </div>
  )
}
