import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import SaleProductForm from "../../components/SaleProductForm";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BACKEND_URL } from "../../utils/constant";
import PurchaseProductForm from "../../components/PurchaseProductForm";
import DefaultDropdown from "../../components/DefaultDropdown";

export default function EditBill() {
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const params = useParams();
  const [currency, setcurrency] = useState();
  const [xData, setxData] = useState();
  const { user, setLoading } = useAuth();
  const { purchase_order_id } = useParams();
  const { handleInputChange, formData, setFormData, submitForm, error } =
    useForm();

  useEffect(() => {
    if (formData.bill_id) {
      return;
    }
    const url = BACKEND_URL + "/bill?included=true&bill_id=" + params.id;
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const temp = response.data.data[0];
        setxData(temp);
        setFormData({
          ...temp,
          items: temp.billDetail,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      total_amount:
        parseInt(formData.sub_total_amount) +
        parseInt(formData.other_charges) +
        parseInt(formData.transportation_charges),
    });

    console.log(formData);
  }, [
    formData.sub_total_amount,
    formData.other_charges,
    formData.transportation_charges,
  ]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      ...formData,
      status: "Pay",
    };
    console.log("form data - ", data);
    submitForm("PUT", "/bill/" + params.id, data, () => {
      history.push("/dashboard/bill");
    });
  };

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/bill">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">{formatMessage("common.editBill")}</h1>
        <h2 className="mt-6" style={{ fontSize: "22px" }}>
          {formatMessage("common.billInformation")}
        </h2>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.billNumber")}
                </label>
                <input
                  disabled
                  required
                  type="text"
                  value={formData.bill_number || ""}
                  name="bill_number"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.purchaseDate")}
                </label>
                <input
                  required
                  disabled
                  type="date"
                  value={
                    formData.purchase_date
                      ? moment(formData.purchase_date).format("YYYY-MM-DD")
                      : ""
                  }
                  name="purchase_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.estimateArrivalTime")}
                </label>
                <input
                  type="date"
                  disabled
                  value={
                    formData.estimated_arrival_date
                      ? moment(formData.estimated_arrival_date).format(
                          "YYYY-MM-DD"
                        )
                      : ""
                  }
                  name="estimated_arrival_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.billDate")}
                </label>
                <input
                  type="date"
                  value={
                    formData.bill_date
                      ? moment(formData.bill_date).format("YYYY-MM-DD")
                      : ""
                  }
                  name="bill_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.dueDate")}
                </label>
                <input
                  type="date"
                  value={
                    formData.due_date
                      ? moment(formData.due_date).format("YYYY-MM-DD")
                      : ""
                  }
                  name="due_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  value={formData.remark || ""}
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>
            <PurchaseProductForm
              isInfo={true}
              formData={formData}
              currName={""}
              setFormdata={setFormData}
            />

            <div className="mt-6 gap-3 mb-5 flex flex-col items-end mr-6">
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.subtotal")}
                </span>
                <span
                  type="number"
                  className="text-center"
                  style={{ width: "155px" }}
                >
                  {formData.sub_total_amount || 0}
                </span>
              </div>
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.otherCharges")}
                </span>
                <input
                  type="number"
                  name="other_charges"
                  value={formData.other_charges || 0}
                  onChange={handleInputChange}
                  className="x-input text-center"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.transportationCharges")}
                </span>
                <input
                  type="number"
                  value={formData.transportation_charges || 0}
                  name="transportation_charges"
                  onChange={handleInputChange}
                  className="x-input text-center"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.total")}
                </span>
                <span
                  type="number"
                  className="text-center"
                  style={{ width: "155px" }}
                >
                  {formData.total_amount || 0}
                </span>
              </div>
            </div>

            <div className="mt-5">
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/bill`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.update")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
