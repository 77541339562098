import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import DefaultDropdown from "../../components/DefaultDropdown";
import { decimalplaces } from "../../utils/constant";

export default function CreateUoc() {
  const { handleInputChange, formData, submitForm, error } = useForm();
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const { user, setLoading } = useAuth();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...formData,
      converted_quantity: 1,
      status: "ACTIVE",
    };

    submitForm("POST", "/uoc", data, () => {
      history.push("/dashboard/uoc");
    });
  };

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/currencyExchange">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">{formatMessage("common.uocCreate")}</h1>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.baseUnit")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.base_unit_id}
                  required
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                  name="base_unit_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.baseQty")}
                </label>
                <input
                  required
                  type="number"
                  name="base_quantity"
                  step={decimalplaces}
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.convertedUnit")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.converted_unit_id}
                  required
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                  name="converted_unit_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.convertQty")}
                </label>
                <input
                  required
                  type="number"
                  name="converted_quantity"
                  value={1}
                  disabled
                  readOnly
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>

            <div>
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/uoc`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.create")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
