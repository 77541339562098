import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-numberformat/polyfill-force";
import "@formatjs/intl-numberformat/locale-data/my";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-datetimeformat/polyfill-force";
import "@formatjs/intl-datetimeformat/locale-data/my";
import "@formatjs/intl-datetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/polyfill-force";
import "@formatjs/intl-relativetimeformat/locale-data/my";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAuth } from "./context/AuthProvider";
import PrivateRoute from "./context/PrivateRoute";
import PublicRoute from "./context/PublicRoute";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import messages from "./utils/messages.json";
import flatten from "flat";
import NotFound from "./pages/NotFound";
import PopupRender from "./components/PopupRender";
import OfficeUser from "./pages/OfficeUser/OfficeUser";
import CreateOfficeUser from "./pages/OfficeUser/CreateOfficeUser";
import EditOfficeUser from "./pages/OfficeUser/EditOfficeUser";
import Merchant from "./pages/Merchant/Merchant";
import CreateMerchant from "./pages/Merchant/CreateMerchant";
import EditMerchant from "./pages/Merchant/EditMerchant";
import Bank from "./pages/Bank/Bank";
import CreateBank from "./pages/Bank/CreateBank";
import EditBank from "./pages/Bank/EditBank";
import BankAccount from "./pages/BankAccount/BankAccount";
import CreateBankAccount from "./pages/BankAccount/CreateBankAccount";
import EditBankAccount from "./pages/BankAccount/EditBankAccount";
import Currency from "./pages/Currency/Currency";
import CreateCurrency from "./pages/Currency/CreateCurrency";
import EditCurrency from "./pages/Currency/EditCurrency";
import CurrencyExchange from "./pages/CurrencyExchange/CurrencyExchange";
import CreateCurrencyExchange from "./pages/CurrencyExchange/CreateCurrencyExchange";
import EditCurrencyExchange from "./pages/CurrencyExchange/EditCurrencyExchange";
import BankTransaction from "./pages/BankTransaction/BankTransaction";
import CreateBankTransaction from "./pages/BankTransaction/CreateBankTranaction";
import SaleOrder from "./pages/SaleOrder/SaleOrder";
import CreateSaleOrder from "./pages/SaleOrder/CreateSaleOrder";
import ProductCategory from "./pages/ProductCategory/ProductCategory";
import CreateProductCategory from "./pages/ProductCategory/CreateProductCategory";
import EditProductCategory from "./pages/ProductCategory/EditProductCategory";
import AddSaleProduct from "./pages/SaleOrder/AddSaleProduct";
import PurchaseOrder from "./pages/PurchaseOrder/PurchaseOrder";
import CreatePurchaseOrder from "./pages/PurchaseOrder/CreatePurchaseOrder";
import AddPurchaseProduct from "./pages/PurchaseOrder/AddPurchaseProduct";
import Bill from "./pages/Bill/Bill";
import CreateBill from "./pages/Bill/CreateBill";
import PaymentMake from "./pages/MakePayment/PaymentMake";
import CreatePaymentMake from "./pages/MakePayment/CreatePaymentMake";
import Invoice from "./pages/Invoice/Invoice";
import CreateInvoice from "./pages/Invoice/CreateInvoice";
import PaymentReceive from "./pages/PaymentReceive/PaymentReceive";
import CreatePaymentReceive from "./pages/PaymentReceive/CreatePaymentReceive";
import TopTenMerchant from "./pages/TopTenMerchant";
import EditSaleOrder from "./pages/SaleOrder/EditSaleOrder";
import EditInvoice from "./pages/Invoice/EditInvoice";
import Supplier from "./pages/Supplier/Supplier";
import CreateSupplier from "./pages/Supplier/CreateSupplier";
import EditSupplier from "./pages/Supplier/EditSupplier";
import EditPurchaseOrder from "./pages/PurchaseOrder/EditPurchaseOrder";
import EditBill from "./pages/Bill/EditBill";
import MainAccount from "./pages/MainAccount/MainAccount";
import SubAccount from "./pages/SubAccount/SubAccount";
import CreateSubAccount from "./pages/SubAccount/CreateSubAccount";
import EditSubAccount from "./pages/SubAccount/EditSubAccount";
import CoaTransaction from "./pages/CoaTransaction/CoaTransaction";
import CreateCoaTransaction from "./pages/CoaTransaction/CreateCoaTransaction";
import EditCoaTransaction from "./pages/CoaTransaction/EditCoaTransaction";

import Report from "./pages/Report/Report";
import OfficeUserReport from "./pages/Report/OfficeUserReport";
import MerchantDataReport from "./pages/Report/MerchantDataReport";
import PurchaseOrderReport from "./pages/Report/PurchaseOrderReport";
import SaleOrderReport from "./pages/Report/SaleOrderReport";
import BankDepositTransactionReport from "./pages/Report/BankDepositTransactionReport";
import BankWithdrawalTransactionReport from "./pages/Report/BankWithdrawalTransactionReport";
import CurrencyExchangeRateReport from "./pages/Report/CurrencyExchangeRateReport";
import ReceivableReportByDateRange from "./pages/Report/ReceivableReportByDateRange";
import ReceivableReportByYear from "./pages/Report/ReceivableReportByYear";
import PayableReportByYear from "./pages/Report/PayableReportByYear";
import PayableReportByDateRange from "./pages/Report/PayableReportByDateRange";
import ProfitAndLossReport from "./pages/Report/ProfitAndLossReport";
import IncomeReport from "./pages/Report/IncomeReport";
import ExpenseReport from "./pages/Report/ExpenseReport";
import OtherIncomeReport from "./pages/Report/OtherIncomeReport";
import BalanceSheet from "./pages/Report/BalanceSheet";
import CoaTransactionReport from "./pages/Report/CoaTransaction";
import Unit from "./pages/Unit/Unit";
import createUnit from "./pages/Unit/CreateUnit";
import EditUnit from "./pages/Unit/EditUnit";
import Uoc from "./pages/Uoc/Uoc";
import CreateUoc from "./pages/Uoc/CreateUoc";
import EditUoc from "./pages/Uoc/EditUoc";

function App() {
  const { user, checkUser, lang, app } = useAuth();

  return (
    <IntlProvider
      locale={lang}
      messages={flatten(messages[lang])}
      defaultLocale="en"
    >
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={Login} />

          <Route path="/dashboard">
            <PrivateRoute
              exact
              path="/dashboard/home"
              component={Dashboard}
              module="dashboard"
            />
            <PrivateRoute
              exact
              path="/dashboard/topTenMerchant"
              component={TopTenMerchant}
              module="dashboard"
            />

            <PrivateRoute
              exact
              path="/dashboard/officeUser"
              component={OfficeUser}
              module="Office User Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/officeUser/create"
              component={CreateOfficeUser}
              module="Office User Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/officeUser/:id/edit"
              component={EditOfficeUser}
              module="Office User Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/merchant"
              component={Merchant}
              module="Merchant Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/merchant/create"
              component={CreateMerchant}
              module="Merchant Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/merchant/:id/edit"
              component={EditMerchant}
              module="Merchant Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/supplier"
              component={Supplier}
              module="Supplier Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/supplier/create"
              component={CreateSupplier}
              module="Supplier Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/supplier/:id/edit"
              component={EditSupplier}
              module="Supplier Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/bank"
              component={Bank}
              module="Bank Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/bank/create"
              component={CreateBank}
              module="Bank Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/bank/:id/edit"
              component={EditBank}
              module="Bank Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/bankAccount"
              component={BankAccount}
              module="Bank Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/bankAccount/create"
              component={CreateBankAccount}
              module="Bank Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/bankAccount/:id/edit"
              component={EditBankAccount}
              module="Bank Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/bankTransaction"
              component={BankTransaction}
              module="Bank Transaction Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/bankTransaction/create"
              component={CreateBankTransaction}
              module="Bank Transaction Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/currency"
              component={Currency}
              module="Currency Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/currency/create"
              component={CreateCurrency}
              module="Currency Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/currency/:id/edit"
              component={EditCurrency}
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/currencyExchange"
              component={CurrencyExchange}
              module="Currency Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/currencyExchange/create"
              component={CreateCurrencyExchange}
              module="Currency Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/currencyExchange/:id/edit"
              component={EditCurrencyExchange}
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/saleOrder"
              component={SaleOrder}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/saleOrder/create"
              component={CreateSaleOrder}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/saleOrder/:id/edit"
              component={EditSaleOrder}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/saleOrder/create/addProduct"
              component={AddSaleProduct}
              module="Sales Order Management"
            />
            {/* <PrivateRoute exact path="/dashboard/currencyExchange/:id/edit" component={EditCurrencyExchange} module="Sales Order Management"/> */}

            <PrivateRoute
              exact
              path="/dashboard/productCategory"
              component={ProductCategory}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/productCategory/create"
              component={CreateProductCategory}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/productCategory/:id/edit"
              component={EditProductCategory}
              module="Sales Order Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/purchaseOrder"
              component={PurchaseOrder}
              module="Purchase Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/purchaseOrder/create"
              component={CreatePurchaseOrder}
              module="Purchase Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/purchaseOrder/:id/edit"
              component={EditPurchaseOrder}
              module="Purchase Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/purchaseOrder/create/addProduct"
              component={AddPurchaseProduct}
              module="Purchase Order Management"
            />
            {/* <PrivateRoute exact path="/dashboard/currencyExchange/:id/edit" component={EditCurrencyExchange} module="Purchase Order Management"/> */}

            <PrivateRoute
              exact
              path="/dashboard/bill"
              component={Bill}
              module="Purchase Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/bill/create/:purchase_order_id"
              component={CreateBill}
              module="Purchase Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/bill/:id/edit"
              component={EditBill}
              module="Purchase Order Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/paymentMake"
              component={PaymentMake}
              module="Purchase Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/paymentMake/create/:bill_id"
              component={CreatePaymentMake}
              module="Purchase Order Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/invoice"
              component={Invoice}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/invoice/create/:sale_order_id"
              component={CreateInvoice}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/invoice/:id/edit"
              component={EditInvoice}
              module="Sales Order Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/paymentReceive"
              component={PaymentReceive}
              module="Sales Order Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/paymentReceive/create/:invoice_id"
              component={CreatePaymentReceive}
              module="Sales Order Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/mainAccount"
              component={MainAccount}
              // module="Reports"
              module="Finance Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/subAccount"
              component={SubAccount}
              // module="Reports"
              module="Finance Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/subAccount/create"
              component={CreateSubAccount}
              // module="Reports"
              module="Finance Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/subAccount/:id/edit"
              component={EditSubAccount}
              // module="Reports"
              module="Finance Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/coaTransaction"
              component={CoaTransaction}
              // module="Reports"
              module="Finance Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/coaTransaction/create"
              component={CreateCoaTransaction}
              // module="Reports"
              module="Finance Management"
            />
            <PrivateRoute
              exact
              path="/dashboard/coaTransaction/:id/edit"
              component={EditCoaTransaction}
              // module="Reports"
              module="Finance Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/unit"
              component={Unit}
              // module="Reports"
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/unit/create"
              component={createUnit}
              // module="Reports"
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/unit/:id/edit"
              component={EditUnit}
              // module="Reports"
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/uoc"
              component={Uoc}
              // module="Reports"
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/uoc/create"
              component={CreateUoc}
              // module="Reports"
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/uoc/:id/edit"
              component={EditUoc}
              // module="Reports"
              module="Currency Management"
            />

            <PrivateRoute
              exact
              path="/dashboard/report"
              component={Report}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/officeUser"
              component={OfficeUserReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/merchantData"
              component={MerchantDataReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/purchaseOrder"
              component={PurchaseOrderReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/saleOrder"
              component={SaleOrderReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/bankDepositTransaction"
              component={BankDepositTransactionReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/bankWithdrawalTransaction"
              component={BankWithdrawalTransactionReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/currencyExchangeRate"
              component={CurrencyExchangeRateReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/receivableReportByDateRange"
              component={ReceivableReportByDateRange}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/receivableReportByYear"
              component={ReceivableReportByYear}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/payableReportByYear"
              component={PayableReportByYear}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/payableReportByDateRange"
              component={PayableReportByDateRange}
              module="Reports"
            />

            <PrivateRoute
              exact
              path="/dashboard/report/profitAndLoss"
              component={ProfitAndLossReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/income"
              component={IncomeReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/expense"
              component={ExpenseReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/otherIncome"
              component={OtherIncomeReport}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/balanceSheet"
              component={BalanceSheet}
              module="Reports"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/coaTransaction"
              component={CoaTransactionReport}
              module="Reports"
            />
          </Route>

          <Route>
            <div className="w-screen h-screen">
              <NotFound />
            </div>
          </Route>
        </Switch>
      </Router>

      {app.popup && <PopupRender popup={app.popup} />}
    </IntlProvider>
  );
}

export default App;
