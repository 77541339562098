import moment from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import SaleProductForm from "../../components/SaleProductForm";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { BACKEND_URL, decimalplaces } from "../../utils/constant";
import PurchaseProductForm from "../../components/PurchaseProductForm";
import NotFound from "../NotFound";
import InvoiceProductTable from "../../components/InvoiceProductTable";
import DefaultDropdown from "../../components/DefaultDropdown";
import { isValidInput } from "../../utils/common";

export default function CreateInvoice() {
  const formatMessage = useFormatMessage();
  const [currency, setcurrency] = useState();
  const [cxRate, setCxRate] = useState();
  const history = useHistory();
  const { user, setLoading } = useAuth();
  const { sale_order_id } = useParams();

  const {
    handleInputChange,
    formData,
    setFormData,
    submitForm,
    error,
    setError,
  } = useForm();

  useEffect(() => {
    (async () => {
      let url = BACKEND_URL + "/common/generateID";
      let response = await axios.post(
        url,
        { name: "invoice" },
        {
          withCredentials: true,
        }
      );
      let invoice_number = response.data.data;

      url =
        BACKEND_URL + "/saleOrder?included=true&sale_order_id=" + sale_order_id;
      response = await axios.get(url, {
        withCredentials: true,
      });
      let tempSale = response.data.data[0];

      let data = {
        ...formData,
        sale_order_id: tempSale.sale_order_id,
        sale_invoice: tempSale.sale_invoice,
        merchant_id: tempSale.merchant_id,
        merchant_name: tempSale.merchant.merchant_name,
        items: tempSale.saleOrderDetail,
        sub_total_amount: tempSale.total_amount,
        invoice_number,
      };

      data.items.map((d) => {
        d.quantity = d.product_qty;
        return d;
      });

      // if (tempPurchase) {
      //     data.purchase_order_id = tempPurchase.purchase_order_id;
      //     data.purchase_order = tempPurchase.purchase_order;
      //     data.items = items.map((d) => {
      //         let purchaseItem = tempPurchase.purchaseOrderDetail.filter(
      //             (x) => x.product_name == d.product_name
      //         )[0];
      //         if (purchaseItem) {
      //             d.quantity = purchaseItem.quantity;
      //             d.purchase_price_mmk = purchaseItem.purchase_price_mmk;
      //             d.selling_price = 0;
      //             d.completed = true;
      //         } else {
      //             d.completed = false;
      //         }
      //         return d;
      //     });
      // }

      setFormData(data);
    })();
  }, []);

  const getUpdatedFormdataForOverallAmount = () => {
    let sub_total = formData.items.reduce((sum, d) => {
      return sum + (parseFloat(d.selling_price * d.quantity) || 0);
    }, 0);

    let taxAmount =
      sub_total * (parseFloat(formData.tax_percentage || 0) / 100);
    let discount = Math.round(formData.discount * 1000) / 1000 || 0;
    let transportation_charges =
      Math.round(formData.transportation_charges * 1000) / 1000 || 0;
    let advance = Math.round(formData.advance * 1000) / 1000 || 0;
    let sub_total_amount = Math.round(sub_total * 1000) / 1000;
    let tax_amount = Math.round(taxAmount * 1000) / 1000;
    let totalamount_discount =
      sub_total + taxAmount + transportation_charges - discount - advance;
    console.log(totalamount_discount);
    return {
      ...formData,
      sub_total_amount: sub_total_amount,
      tax_amount: tax_amount,
      total_amount: Math.round(totalamount_discount * 1000) / 1000,
    };
  };

  useEffect(() => {
    if (!formData.items) {
      return;
    }

    const data = getUpdatedFormdataForOverallAmount();
    setFormData(data);
  }, [
    formData.tax_percentage,
    formData.items,
    formData.transportation_charges,
    formData.discount,
    formData.advance,
  ]);
  // useEffect(() => {
  //     if (!formData.items) {
  //         return;
  //     }
  //     let data = getUpdatedFormdataForOverallAmount();
  //     data.items = getUpdatedItemAmount();
  //     setFormData(data);
  // }, [formData.items]);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      ...formData,
      items: formData.items.map((d) => {
        d.amount = d.selling_price * d.quantity;
        return d;
      }),
    };
    submitForm("POST", "/invoice/cascadeCreate", data, () => {
      history.push("/dashboard/invoice");
    });
  };

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/invoice">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">
          {formatMessage("common.createInvoice")}
        </h1>
        <h2 className="mt-6" style={{ fontSize: "22px" }}>
          {formatMessage("common.invoiceInformation")}
        </h2>
        <div className="form-container">
          <form
            autoComplete="off"
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.invoiceNumber")}
                </label>
                <input
                  disabled
                  required
                  type="text"
                  value={formData.invoice_number || ""}
                  name="invoice_number"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>

              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.merchantName")}
                </label>
                <input
                  required
                  disabled
                  type="text"
                  value={formData.merchant_name ? formData.merchant_name : ""}
                  name="purchase_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.invoiceDate")}
                </label>
                <input
                  required
                  type="date"
                  name="invoice_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>

              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.currency")}
                </label>
                <DefaultDropdown
                  required
                  extQuery={`&base_currency_flag=NO`}
                  value={formData.currency_id}
                  entity="currency"
                  setData={setcurrency}
                  name="currency_id"
                  onChange={handleInputChange}
                  title="currency_name"
                  id="currency_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.currencyExchange")}
                </label>
                <DefaultDropdown
                  required
                  setData={setCxRate}
                  extQuery={`&exchange_date=${formData.invoice_date}&converted_currency_id=${formData.currency_id}`}
                  value={formData.cx_rate_id}
                  entity="currencyExchange"
                  name="cx_rate_id"
                  onChange={handleInputChange}
                  title="base_unit"
                  id="cx_rate_id"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.dueDate")}
                </label>
                <input
                  type="date"
                  // defaultValue={formData.due_date || ""}
                  name="invoice_due_date"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
              <div className="grow">
                <label className="x-label">
                  {formatMessage("common.remark")}
                </label>
                <input
                  type="text"
                  name="remark"
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>
            <InvoiceProductTable
              currency={currency}
              cxRate={cxRate}
              setFormdata={setFormData}
              formData={formData}
            />

            <div className="mt-6 gap-3 mb-5 flex flex-col items-end mr-6">
              <div className="flex w-96 h-12 leading-10">
                <span className="block flex-grow">
                  {formatMessage("common.subtotal")}
                </span>
                <span
                  type="number"
                  className="text-center"
                  style={{ width: "155px" }}
                >
                  {formData.sub_total_amount}
                </span>
              </div>
              <div className="flex w-96">
                <span className="block flex-grow h-12 leading-10">
                  {formatMessage("common.tax")}

                  <input
                    type="number"
                    name="tax_percentage"
                    onChange={handleInputChange}
                    className="x-input text-center ml-3"
                    style={{
                      width: "55px",
                      padding: "8px 4px",
                    }}
                  />
                </span>
                <span
                  type="number"
                  className="text-center"
                  style={{ width: "155px" }}
                >
                  {formData.tax_amount}
                </span>
              </div>
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.transportationCharges")}
                </span>
                <input
                  type="number"
                  name="transportation_charges"
                  onChange={handleInputChange}
                  className="x-input text-center"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="flex w-96">
                <span className="block flex-grow">
                  {formatMessage("common.advance")}
                </span>
                <input
                  type="number"
                  name="advance"
                  onChange={handleInputChange}
                  className="x-input text-center"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="flex w-96">
                <span className="block flex-grow">Discount</span>
                <input
                  type="number"
                  name="discount"
                  step={decimalplaces}
                  onChange={handleInputChange}
                  className="x-input p-0 text-center"
                  style={{ width: "155px" }}
                />
              </div>
              <div className="flex w-96">
                <span className="flex-grow flex h-12 leading-10">
                  {formatMessage("common.total")}
                </span>
                <span
                  type="number"
                  className="text-center"
                  style={{ width: "155px" }}
                >
                  {formData.total_amount}
                </span>
              </div>
            </div>

            <div className="mt-5">
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/invoice`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.create")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
