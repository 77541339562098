import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL, decimalplaces } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import DefaultDropdown from "../../components/DefaultDropdown";
import PasswordInput from "../../components/PasswordInput";
import NotFound from "../NotFound";
import { isValidInput } from "../../utils/common";

export default function EditUoc() {
  const { handleInputChange, error, formData, setFormData, submitForm } =
    useForm();
  const history = useHistory();
  const params = useParams();
  const { setLoading } = useAuth();
  const formatMessage = useFormatMessage();
  const [xData, setxData] = useState({});
  const [errorBase, setErrorBase] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValidInput(formData.base_quantity)) {
      setErrorBase("Input value should be three decimal");
      return;
    }
    setLoading(true);
    const data = {
      ...formData,
      status: "ACTIVE",
    };
    submitForm("PUT", "/uoc/" + params.id, data, () => {
      history.push("/dashboard/uoc");
    });
  };

  useEffect(() => {
    const url = BACKEND_URL + "/uoc?uoc_id=" + params.id;
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const temp = response.data.data[0];
        console.log(temp);
        setxData(temp);
        setFormData({
          base_unit_id: temp.base_unit_id,
          base_quantity: temp.base_quantity,
          converted_unit_id: temp.converted_unit_id,
          converted_quantity: temp.converted_quantity,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);

  if (!xData) {
    return <NotFound />;
  }

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/uoc">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">{formatMessage("common.uoc")}</h1>
        <div className="form-container">
          <form
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div
              className="grid grid-cols-2 gap-9"
              style={{ maxWidth: "995px" }}
            >
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.baseUnit")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.base_unit_id || ""}
                  required
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                  name="base_unit_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.baseQty")}
                </label>
                <input
                  required
                  type="number"
                  name="base_quantity"
                  value={formData.base_quantity || ""}
                  onChange={handleInputChange}
                  className="x-input"
                />
                {errorBase && (
                  <div className="text-red-500 mt-2">{errorBase}</div>
                )}
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.convertedUnit")}
                </label>
                <DefaultDropdown
                  onChange={handleInputChange}
                  value={formData.converted_unit_id || ""}
                  required
                  entity="unit"
                  id="unit_id"
                  title="unit_name"
                  name="converted_unit_id"
                />
              </div>
              <div className="grow">
                <label className="x-label input-required">
                  {formatMessage("common.convertQty")}
                </label>
                <input
                  required
                  type="number"
                  name="converted_quantity"
                  value={formData.converted_quantity || ""}
                  onChange={handleInputChange}
                  className="x-input"
                />
              </div>
            </div>

            <div>
              {error ? (
                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/uoc`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.finish")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
