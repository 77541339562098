import React from 'react'
import '../css/dashboardTemplate.css'
import logo from '../img/app_logo.png'
import Navigation from './Navigation'
import UserProfile from './UserProfile'

function DashboardTemplate({children}) {
  return (
    <div className='page'>
        <div className='dashboard-layout'>
            <div className='side-nav'>
              <div className='dashboard-logo'>
                <img className='dashboard-logo' src={logo}/>
              </div>
              <Navigation/>
              <UserProfile/>
            </div>
            <div className='sub-layout'>
                {children}

            </div>
        </div>

    </div>
  )
}

export default DashboardTemplate