import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency } from "../../utils/common";
import { Pagination } from "../../components/Pagination";
import { formatDateToYYYYMMDD } from "../../utils/common";
import * as XLSX from 'xlsx';

const ProfitAndLossReport = () => {
    const handleExportClick = (fileName) => {
        const table = document.querySelector("#xlsx-export").cloneNode(true);
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName)
    };
    const formatMessage = useFormatMessage();

    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(oneMonthAgo));
    const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));
    const [data, setData] = useState([]);

    useEffect(() => {
        let url = BACKEND_URL + `/reports/profitandlossreport?startDate=${startDate}&endDate=${endDate}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData(response.data.data);
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [startDate, endDate]);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.profitAndLossReport")}</h1>
                <div className="flex">
                    <input
                        required
                        name="start_date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        className="x-input mr-3"
                        type="date"
                    />
                    <input
                        required
                        name="end_date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        className="x-input mr-4"
                        type="date"
                    />
                    <button className="header-img-button" onClick={() => {
                        handleExportClick(`Profit_and_Loss_Report_from_${startDate}_to_${endDate}.xlsx`)
                    }}>
                        <img src={printIcon} />
                    </button>
                </div>

            </div>
            <div className="table-container">
                <table className="x-table" id="xlsx-export">
                    <thead>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            {/* <th className="hidden" colSpan={5}></th> */}
                            <th className="hidden">{formatMessage("common.from")}</th>
                            <th className="hidden">{startDate}</th>
                            <th className="hidden">{formatMessage("common.to")}</th>
                            <th className="hidden">{endDate}</th>
                        </tr>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={2}></th>
                        </tr>
                        <tr>
                            <th>{formatMessage("common.title")}</th>
                            <th>{formatMessage("common.amount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, i) => {
                            return (
                                <tr key={i}
                                    // className="hover-effect" 
                                    style={{ backgroundColor: item["Bold"] === "Yes" ? "#F5F5F5" : null }}>
                                    <td style={{ fontWeight: item["Bold"] === "Yes" ? 'bolder' : null }}>{item["Title"]}</td>
                                    <td style={{ fontWeight: item["Bold"] === "Yes" ? 'bolder' : null }}>{parseInt(item["Balance"]).toLocaleString("en-US")}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
            </div>
        </div>
    );
}

export default ProfitAndLossReport;
