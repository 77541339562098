import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL, GeneralStatus } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashCanIcon from "../../img/icon-trash.png";
import detailIcon from "../../img/icon-details.png";
import crossIcon from "../../img/icon-cross.png";
import reloadIcon from "../../img/icon-reload.png";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { useAuth } from "../../context/AuthProvider";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";
import { ResetPasswordPopup } from "../../components/ResetPasswordPopup";

export default function BankAccount() {
    const history = useHistory();
    const { setPopup } = useAuth();
    const currentPath = history.location.pathname;
    const [isOptionOpen, setisOptionOpen] = useState(false);
    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: BACKEND_URL + "/bankAccount",
        defaultQuery: "",
    });
    const formatMessage = useFormatMessage();

    const toggleOptions = (id) => {
        const optionEl = document.getElementById(id);
        const activeOptionEl = document.querySelectorAll(".popup-options.open");
        console.log(activeOptionEl);
        if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
            optionEl.classList.toggle("open");
            setisOptionOpen(optionEl.classList.contains("open"));
        }
    };

    const onSearchChange = (e) => {
        const text = e.target.value;
        setQuery({
            bank_account_holder_name: text.trim(),
        });
    };

    const onDeleteClick = (e) => {
        e.preventDefault();
        const id = e.target.parentNode.id
            ? e.target.parentNode.id
            : e.target.parentNode.parentNode.id;
        setPopup(
            <DeleteConfirmPopup id={id} reload={reload} entity="bankAccount" />
        );
    };

    useEffect(() => {
        const openedEl = document.querySelector(".popup-options.open");

        const handleDocumentClick = (e) => {
            if (e.target != openedEl && !openedEl.contains(e.target)) {
            }
            openedEl.classList.remove("open");
            setisOptionOpen(false);
        };
        if (openedEl) {
            setTimeout(() => {
                document.addEventListener("click", handleDocumentClick);
            }, 100);
        }
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [isOptionOpen]);

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>{formatMessage("common.bankAccountList")}</h1>
                <div className="search-bar">
                    <img src={searchIcon} />
                    <input
                        className="x-input"
                        type="search"
                        onChange={onSearchChange}
                    />
                </div>
                <Link
                    to="/dashboard/bankAccount/create"
                    className="create-button"
                >
                    {formatMessage("common.createNew")}
                </Link>
            </div>

            <div className="table-container">
                <table className="x-table">
                    <thead>
                        <tr>
                            <th>{formatMessage("common.bankName")}</th>
                            <th>{formatMessage("common.accountNumber")}</th>
                            <th>{formatMessage("common.accountHolderName")}</th>
                            <th>{formatMessage("common.balance")}</th>
                            <th className="button-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.results.map((item, i) => {
                            const index = (data.page - 1) * data.limit + i + 1;
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{item.bank && item.bank.bank_name}</td>
                                    <td>{item.bank_account}</td>
                                    <td>{item.bank_account_holder_name}</td>
                                    <td>{item.balance}</td>
                                    <td className="button-col">
                                        <div className="flex items-center">
                                            <span
                                                className={
                                                    item.status === "ACTIVE"
                                                        ? "flex mr-7 items-center justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-green-100 text-green-800"
                                                        : "flex mr-7 items-center justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-gray-100"
                                                }
                                            >
                                                {GeneralStatus[item.status]}
                                            </span>
                                            <img
                                                src={detailIcon}
                                                className="col-button"
                                                onClick={() =>
                                                    setPopup(
                                                        <DetailPopup
                                                            data={item}
                                                        />
                                                    )
                                                }
                                            />
                                            <img
                                                className="col-button"
                                                src={dotsIcon}
                                                onClick={
                                                    item.status == "ACTIVE"
                                                        ? () =>
                                                              toggleOptions(
                                                                  item.bank_account_id
                                                              )
                                                        : null
                                                }
                                            />
                                        </div>
                                        <div
                                            className="popup-options"
                                            id={item.bank_account_id}
                                        >
                                            <Link
                                                to={`${currentPath}/${item.bank_account_id}/edit`}
                                            >
                                                <img src={editIcon} />
                                                {formatMessage("common.edit")}
                                            </Link>
                                            <a href="" onClick={onDeleteClick}>
                                                <img src={trashCanIcon} />
                                                {formatMessage("common.remove")}
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="pagination-container">
                <Pagination data={data} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
}

const DetailPopup = ({ data }) => {
    const formatMessage = useFormatMessage();
    const { setPopup } = useAuth();

    return (
        <div className="popup relative">
            <h4 className="mb-10">
                {formatMessage("common.bankAccount")}
                {formatMessage("common.information")}
            </h4>
            <div className="mb-8">
                <span className="inline-block w-60">
                    {formatMessage("common.bankName")}
                </span>
                <span className="mr-20">:</span>
                <span className="inline-block w-52">
                    {data.bank && data.bank.bank_name}
                </span>
            </div>
            <div className="mb-8">
                <span className="inline-block w-60">
                    {formatMessage("common.accountNumber")}
                </span>
                <span className="mr-20">:</span>
                <span className="inline-block w-52">{data.bank_account}</span>
            </div>
            <div className="mb-8">
                <span className="inline-block w-60">
                    {formatMessage("common.accountHolderName")}
                </span>
                <span className="mr-20">:</span>
                <span className="inline-block w-52">
                    {data.bank_account_holder_name}
                </span>
            </div>
            <div className="mb-8">
                <span className="inline-block w-60">
                    {formatMessage("common.currency")}
                </span>
                <span className="mr-20">:</span>
                <span className="inline-block w-52">{data.currency}</span>
            </div>
            <div className="mb-8">
                <span className="inline-block w-60">
                    {formatMessage("common.balance")}
                </span>
                <span className="mr-20">:</span>
                <span className="inline-block w-52">{data.balance}</span>
            </div>

            <img
                className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
                src={crossIcon}
                onClick={() => setPopup(null)}
            />
        </div>
    );
};
