import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import {
    BACKEND_URL,
    expenseMainAccounts,
    incomeMainAccounts,
    mainAccountsWithPaidThrough,
} from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import NotFound from "../NotFound";
import DataDropdown from "../../components/DataDropdown";
import DefaultDropdown from "../../components/DefaultDropdown";

export default function EditCoaTransaction() {
    const { handleInputChange, error, formData, setFormData, submitForm } =
        useForm();
    const history = useHistory();
    const params = useParams();
    const { setLoading } = useAuth();
    const formatMessage = useFormatMessage();
    const [showPaidThrough, setShowPaidThrough] = useState(false);
    const [flag, setFlag] = useState("INCOME");
    const [mainAccounts, setMainAccounts] = useState();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        if (!showPaidThrough)
        {
            data.paid_through = null
        }
        submitForm("PUT", "/finance/coaTransaction/" + params.id, data, () => {
            history.push("/dashboard/coaTransaction");
        });
    };

    useEffect(() => {
        const url =
            BACKEND_URL +
            "/finance/coaTransaction?included=true&isOrigin=1&coa_transaction_id=" +
            params.id;
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                (async () => {
                    let temp = response.data.data;
                    if (temp.length == 0) {
                        return;
                    }
                    temp = temp[0];
                    let subAccount = temp.subAccount
                        ? temp.subAccount
                        : temp.lockSubAccount;
                    let flag = incomeMainAccounts.includes(
                        subAccount.mainAccount.main_account_name
                    )
                        ? "INCOME"
                        : "EXPENSE";
                    setFlag(flag);
                    let paid_through_link_id = temp.linkCoaTransaction.length != 0
                        ? temp.linkCoaTransaction[0].coa_transaction_id
                        : "";
                    if (paid_through_link_id)
                    {
                        response = await axios.get(
                            BACKEND_URL +
                                "/finance/coaTransaction?included=true&coa_transaction_id=" +
                                paid_through_link_id,
                            { withCredentials: true }
                        );
                        let data = response.data.data[0];
                        var paid_through = data.sub_account_id
                            ? data.sub_account_id
                            : data.lock_sub_account_id;

                    }
                    temp = {
                        ...temp,
                        sub_account_id: subAccount.sub_account_id
                            ? subAccount.sub_account_id
                            : subAccount.lock_sub_account_id,
                        main_account_id: subAccount.mainAccount.main_account_id,
                        amount: temp.credit != 0 ? temp.credit : temp.debit,
                        flag,
                        paid_through,
                    };
                    console.log(temp);
                    setFormData(temp);
                })();
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        if (!mainAccounts || !formData.main_account_id) return;
        let currentMainAccount = mainAccounts.filter(
            (d) => formData.main_account_id == d.main_account_id
        )[0];
        setShowPaidThrough(
            mainAccountsWithPaidThrough.includes(
                currentMainAccount.main_account_name
            )
        );
    }, [formData.main_account_id]);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/coaTransaction">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.editCoaTransaction")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label
                                    className="x-label input-required"
                                    style={{ marginBottom: "28px !important" }}
                                >
                                    {formatMessage("common.mainAccountName")}
                                </label>
                                <input
                                    type="radio"
                                    id="income"
                                    required
                                    name="flag"
                                    value="INCOME"
                                    checked={formData.flag == "INCOME"}
                                    onChange={handleInputChange}
                                />
                                 <label htmlFor="income">Income</label>{" "}
                                <input
                                    className="ml-10"
                                    type="radio"
                                    id="expense"
                                    required
                                    name="flag"
                                    checked={formData.flag == "EXPENSE"}
                                    onChange={handleInputChange}
                                    value="EXPENSE"
                                />
                                  <label htmlFor="expense">Expense</label>
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.mainAccountName")}
                                </label>
                                <DefaultDropdown
                                    required
                                    entity="finance/mainAccount"
                                    setData={setMainAccounts}
                                    extQuery={"&flag=" + formData.flag}
                                    value={formData.main_account_id || ""}
                                    name="main_account_id"
                                    onChange={handleInputChange}
                                    title="main_account_name"
                                    id="main_account_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.subAccountName")}
                                </label>
                                <DefaultDropdown
                                    required
                                    entity="finance/subAccount"
                                    extQuery={
                                        "&main_account_id=" +
                                        formData.main_account_id
                                    }
                                    value={formData.sub_account_id || ""}
                                    name="sub_account_id"
                                    onChange={handleInputChange}
                                    title="sub_account_name"
                                    id="sub_account_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.transactionDate")}
                                </label>
                                <input
                                    required
                                    type="date"
                                    defaultValue={
                                        formData.transaction_date || ""
                                    }
                                    name="transaction_date"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.amount")}
                                </label>
                                <input
                                    required
                                    value={formData.amount || 0}
                                    type="number"
                                    name="amount"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>

                            {showPaidThrough && (
                                <div className="grow">
                                    <label className="x-label input-required">
                                        {formatMessage("common.paidThrough")}
                                    </label>
                                    <DefaultDropdown
                                        required
                                        entity="finance/paidThroughSubAccount"
                                        extQuery={
                                            "&main_account_id=" +
                                            formData.main_account_id
                                        }
                                        value={formData.paid_through || ""}
                                        name="paid_through"
                                        onChange={handleInputChange}
                                        title="sub_account_name"
                                        id="sub_account_id"
                                    />
                                </div>
                            )}
                        </div>
                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/coaTransaction`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.finish")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
