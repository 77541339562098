import React, { useEffect, useState, useRef } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL, GeneralStatus } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashCanIcon from "../../img/icon-trash.png";
import detailIcon from "../../img/icon-details.png";
import crossIcon from "../../img/icon-cross.png";
import reloadIcon from "../../img/icon-reload.png";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { useAuth } from "../../context/AuthProvider";
import DeleteConfirmPopup from "../../components/DeleteConfirmPopup";
import moment from "moment";
import { formatCurrency } from "../../utils/common";
import { useReactToPrint } from "react-to-print";
import logo from "../../img/app_logo.png";

export default function Invoice() {
  const history = useHistory();
  const { setPopup } = useAuth();
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const { data, setCurrentPage, setQuery, reload } = useFetchData({
    url: BACKEND_URL + "/invoice",
    defaultQuery: "",
  });
  const formatMessage = useFormatMessage();
  let statusColor = {
    Pay: "bill-pay",
    "Partial Paid": "bill-partial-paid",
    Paid: "bill-paid",
  };

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  const onSearchChange = (e) => {
    const text = e.target.value;
    setQuery({
      invoice_number: text.trim(),
      search_param: text.trim(),
    });
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id
      ? e.target.parentNode.id
      : e.target.parentNode.parentNode.id;
    setPopup(<DeleteConfirmPopup id={id} reload={reload} entity="invoice" />);
  };

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
      }
      openedEl.classList.remove("open");
      setisOptionOpen(false);
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  return (
    <div className="page-layout">
      <div className="page-header">
        <h1>{formatMessage("common.invoiceList")}</h1>
        <div className="search-bar">
          <img src={searchIcon} />
          <input className="x-input" type="search" onChange={onSearchChange} />
        </div>
      </div>

      <div className="table-container">
        <table className="x-table header-gray">
          <thead>
            <tr>
              <th>{formatMessage("common.invoiceNumber")}</th>
              <th>{formatMessage("common.saleOrderId")}</th>
              <th>{formatMessage("common.merchantName")}</th>
              <th>{formatMessage("common.totalAmount")}</th>
              <th>{formatMessage("common.remainingAmount")}</th>
              <th>{formatMessage("common.dueDate")}</th>
              <th className="button-col" style={{ minWidth: "160px" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((item, i) => {
              const index = (data.page - 1) * data.limit + i + 1;
              return (
                <tr key={i}>
                  <td>{item.invoice_number}</td>
                  <td>{item.sale_invoice}</td>
                  <td>{item.merchant.merchant_name}</td>
                  <td>{formatCurrency(item.total_amount)}</td>
                  <td>{formatCurrency(item.remainingAmount)}</td>
                  <td>
                    {item.invoice_due_date
                      ? moment(item.invoice_due_date).format("DD/MM/YYYY")
                      : ""}
                  </td>
                  <td className="button-col" style={{ minWidth: "160px" }}>
                    <div className="flex items-center">
                      <a
                        href={
                          item.status == "Pay" || item.status == "Partial Paid"
                            ? `/dashboard/paymentReceive/create/${item.invoice_id}`
                            : "#"
                        }
                        className={
                          "flex mr-7 items-center cursor-pointer justify-center flex-shrink-0 w-24 h-7 rounded-2xl " +
                          statusColor[item.status]
                        }
                      >
                        {item.status}
                      </a>
                      <a
                        href="#"
                        className="flex mr-7 items-center cursor-pointer justify-center flex-shrink-0 w-24 h-7 rounded-2xl bg-cyan-400	"
                        onClick={() => setPopup(<PrintPopup data={item} />)}
                      >
                        Print
                      </a>
                      <img
                        src={detailIcon}
                        className="col-button"
                        onClick={() => setPopup(<DetailPopup data={item} />)}
                      />
                      <img
                        className="col-button"
                        src={dotsIcon}
                        onClick={
                          item.paymentReceive.length == 0
                            ? () => toggleOptions(item.invoice_id)
                            : null
                        }
                      />
                    </div>
                    <div className="popup-options" id={item.invoice_id}>
                      <Link to={`${currentPath}/${item.invoice_id}/edit`}>
                        <img src={editIcon} />
                        {formatMessage("common.edit")}
                      </Link>
                      <a href="" onClick={onDeleteClick}>
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

const PrintPopup = ({ data }) => {
  const { setPopup } = useAuth();
  console.log(data);
  const headerStyle = {
    textAlign: "start",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div
      className="voucher popup relative"
      style={{ height: "95%", overflowY: "scroll" }}
    >
      <div ref={componentRef} style={{ width: "21cm", height: "29.7cm" }}>
        <div style={{ height: "8.7cm" }}>
          <div
            style={{
              height: "5cm",
              display: "flex",
              padding: "0.5cm",
              gap: "15px",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <div>
              <img
                style={{
                  width: "160px",
                  height: "160px",
                  maxWidth: "none",
                  // marginBottom: "10px",
                }}
                src={logo}
              />
            </div>
            <div style={headerStyle}>
              <h1
                style={{
                  color: "#7d2f22",
                  margin: 0,
                  fontStyle: "italic",
                  fontSize: "55px",
                  fontWeight: "bold",
                  // marginRight: "40px",
                }}
              >
                LUCKY XD Co.,LTD
              </h1>
              <h1
                style={{
                  color: "blue",
                  // margin: 0,
                  fontSize: "36px",
                  fontFamily: "cursive",
                  fontWeight: "bold",
                  marginRight: "40px",
                  letterSpacing: "8.5px",
                }}
              >
                幸运祥达进出口有限公司
              </h1>
              <h1
                style={{
                  color: "green",
                  margin: 0,
                  fontFamily: "cursive",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                ရောင်းဝယ်ရေး နှင့် သွင်းကုန်/ထုတ်ကုန်အကျိုးဆောင်လုပ်ငန်း
              </h1>
            </div>
          </div>
          <div style={{ height: "3.7cm" }}>
            <div
              style={{
                paddingLeft: "0.5cm",
                paddingRight: "0.5cm",
                paddingBottom: "0.1cm",
                paddingTop: "0.1cm",
                backgroundColor: "#0b0b9f",
                width: "95%",
                margin: "0 auto",
              }}
            >
              <p style={{ color: "white", fontSize: "16px" }}>
                NO.(509/3),(84)Street, Between (42x43)Street, Shwe Hpone Shein
                Quarter Mahar Aung Myay Tsp, Mandalay Region, Myanmar.
                09-955333222, 09-201849, 09-73332222, 15969262223
              </p>
            </div>
            <div
              style={{
                fontSize: "20px",
                padding: "0 0.5cm",
                marginTop: "5px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // padding: 0.1cm 0",
                  justifyContent: "space-between",
                }}
              >
                <p>Customer Name : {data.merchant?.merchant_name ?? ""}</p>
                <p>Date: {data.invoice_date ?? ""}</p>
              </div>
              <div style={{ alignSelf: "start" }}>
                <p>Car No : _ _ _ _ _ _ _ _ _ _ _ _</p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            // marginTop: "20px",
            height: "21cm",
            display: "flex",
            padding: "0.5cm",
            paddingTop: "0",
            textAlign: "center",
          }}
        >
          <table
            className="image-container"
            style={{ width: "100%", borderSpacing: "2px", borderRadius: "3px" }}
          >
            <thead>
              <tr>
                <th style={{ width: "50px", border: "1px solid black" }}>No</th>
                <th style={{ width: "250px", border: "1px solid black" }}>
                  Particulars
                </th>
                <th style={{ width: "130px", border: "1px solid black" }}>
                  Quantity
                </th>
                <th style={{ width: "130px", border: "1px solid black" }}>
                  Unit Price
                </th>
                <th style={{ border: "1px solid black" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(15)].map((_, index) => {
                const item = data?.invoiceDetail[index];
                // console.log(data);
                return (
                  <tr key={index}>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {index + 1}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {item?.product_name}
                      {item?.product_name &&
                        item?.remark &&
                        `   (${item?.remark})`}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {item?.quantity}
                      {item?.unit?.unit_name && `   (${item.unit.unit_name})`}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {item?.selling_price}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {item?.amount ? Math.round(item.amount) : ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={3} style={{ fontStyle: "italic", border: "none" }}>
                  {" "}
                  Signature _ _ _ _ _ _ _ _ _ _ _
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  Total Amount
                </td>
                <td style={{ border: "1px solid black" }}>
                  {data?.sub_total_amount}
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ border: "none", fontSize: "16px" }}>
                  ကားတင်ကုန်ပစ္စည်းအရေအတွက်ကိုက်ညီမှု ရှိမရှိ သေချာစွာစစ်ဆေးပါ။
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  Advance
                </td>
                <td style={{ border: "1px solid black" }}>{data?.advance ?? 0}</td>
              </tr>

              <tr>
                <td colSpan={3} style={{ border: "none", fontSize: "15px" }}>
                  {" "}
                  မိမိကားပေါ်တင်ပြီးပစ္စည်းများ
                  ပျက်စီးပျောက်ဆုံးပါကမိမိတာဝန်သာဖြစ်သည်။
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  Discount
                </td>
                <td style={{ border: "1px solid black" }}>{data?.discount}</td>
              </tr>
              <tr>
                <td colSpan={3} style={{ border: "none" }}>
                  {" "}
                </td>

                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  Balance
                </td>
                <td style={{ border: "1px solid black" }}>
                  {data?.sub_total_amount -
                    ( ( data?.discount ?  parseFloat(data?.discount) : 0) +
                      ( data?.advance ? parseFloat(data?.advance) : 0))}
                      {/* {data?.advance } */}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          width: "21cm",
          padding: "2rem",
          justifyContent: "center",
          // marginTop: "50px",
        }}
      >
        <button className="black-btn" onClick={handlePrint}>
          Print
        </button>
      </div>
      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        src={crossIcon}
        onClick={() => setPopup(null)}
      />
    </div>
  );
};

const DetailPopup = ({ data }) => {
  const formatMessage = useFormatMessage();
  const { setPopup } = useAuth();

  return (
    <div
      className="popup relative"
      style={{ maxWidth: "1080px", width: "100%" }}
    >
      <h4 className="mb-10">
        {formatMessage("common.invoice")}
        {formatMessage("common.information")}
      </h4>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.invoiceNumber")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.invoice_number}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.merchantName")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.merchant.merchant_name}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.invoiceDate")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">
          {data.invoice_date
            ? moment(data.invoice_date).format("DD/MM/YYYY")
            : ""}
        </span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.dueDate")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">
          {data.invoice_due_date
            ? moment(data.invoice_due_date).format("DD/MM/YYYY")
            : ""}
        </span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.subtotal")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.sub_total_amount}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">{formatMessage("common.tax")}</span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.tax_amount}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.totalAmount")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.total_amount}</span>
      </div>
      <div className="mb-8">
        <span className="inline-block w-60">
          {formatMessage("common.remark")}
        </span>
        <span className="mr-20">:</span>
        <span className="inline-block w-52">{data.remark}</span>
      </div>

      <div className="w-full border-t border-t-gray-500">
        <h3 className="sub-title mb-4 mt-3">
          {formatMessage("common.productList")}
        </h3>
        <table className="popup-table">
          <thead>
            <tr>
              <td>{formatMessage("common.productName")}</td>
              <td>{formatMessage("common.quantity")}</td>
              <td>{formatMessage("common.unit")}</td>
              <td>{formatMessage("common.saleType")}</td>
              <td>{formatMessage("common.purchasePriceMMK")}</td>
              <td>{formatMessage("common.sellingPrice")}</td>
            </tr>
          </thead>
          <tbody>
            {data.invoiceDetail.map((d, i) => (
              <tr key={i}>
                <td>{d.product_name}</td>
                <td>x{d.quantity}</td>
                <td>{d.unit_id ? d.unit.unit_name : ""}</td>
                <td>x{d.sale_type_id}</td>
                <td>{d.purchase_price_mmk}</td>
                <td>{d.selling_price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        src={crossIcon}
        onClick={() => setPopup(null)}
      />
    </div>
  );
};
