import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { API_URL, BACKEND_URL } from "../utils/constant";
import { useCheckUser } from "../hooks/useCheckUser";
import { useSwitchLang } from "../hooks/useSwitchLang";
import PopupRender from "../components/PopupRender";

const AuthContext = createContext(null);

export const useAuth = () => {
    const auth = useContext(AuthContext);
    return auth;
};

const AuthProvider = ({ children }) => {
    const { user, setUser, isLoading, checkUser } = useCheckUser();
    const [loading, setLoading] = useState(false);
    const { lang, switchLang } = useSwitchLang();
    const [app, setApp] = useState({
        popup: null,
    })

    const setPopup = (comp) => {
        setApp({
            ...app,
            popup: comp
        })
    }

    // useEffect(() => {
    //     if (!loading)
    //     {
    //         document.getElementById('external-popup').classList.remove('show')
    //     }
    // }, [loading])

    const logout = () => {
        const url = BACKEND_URL + "/auth/logout";
        axios
            .request({
                method: "POST",
                url,
                withCredentials: true,
            })
            .then((response) => {
                console.log("logout");
                localStorage.setItem('user', '{}');
                setUser(null);
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                logout,
                checkUser,
                lang,
                switchLang,
                loading,
                setLoading,
                app,
                setPopup
            }}
        >
            {!isLoading && children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
