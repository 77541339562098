import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import DefaultDropdown from "../../components/DefaultDropdown";
import PasswordInput from "../../components/PasswordInput";
import NotFound from "../NotFound";

function EditOfficeUser() {
    const { handleInputChange, error, formData, setFormData, submitForm } = useForm();
    const history = useHistory();
    const params = useParams();
    const { setLoading } = useAuth();
    const formatMessage = useFormatMessage();
    const [xData, setxData] = useState({});


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: "ACTIVE",
        };
        submitForm("PUT", "/user/" + params.id, data, () => {
            history.push("/dashboard/officeUser");
        });
    };

    useEffect(() => {
        const url = BACKEND_URL + "/user?status=ACTIVE&user_id=" + params.id;
        axios
            .get(url, { withCredentials: true })
            .then((response) => {
                const temp = response.data.data[0];
                setxData(temp);
                setFormData({
                    role_id: temp.role_id,
                    name: temp.name,
                    username: temp.username,
                    user_phone1: temp.user_phone1,
                    remark: temp.remark,
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, []);


    if (!xData)
    {
        return <NotFound/>
    }
    
    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/officeUser">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.officeUser")}
                </h1>
                <div className="form-container">
                    <form
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.userRole")}
                                </label>
                                <DefaultDropdown
                                    onChange={handleInputChange}
                                    value={formData.role_id || ""}
                                    required
                                    entity="user/role"
                                    id="role_id"
                                    title="role_name"
                                    name="role_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.name")}
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="name"
                                    value={formData.name || ""}
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.username")}
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="username"
                                    value={formData.username || ""}
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.phone")}
                                </label>
                                <input
                                    type="number"
                                    name="user_phone1"
                                    value={formData.user_phone1 || ""}
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.remark")}
                                </label>
                                <input
                                    type="text"
                                    name="remark"
                                    value={formData.remark || ""}
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/officeUser`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.finish")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditOfficeUser;
