import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency } from "../../utils/common";
import { Pagination } from "../../components/Pagination";
import * as XLSX from 'xlsx';

const data = {
    results: {
        data: [
            {
                "name": "Peter",
                "invoice_no": "xxxxxxx",
                "invoice_date": "2.2.2023",
                "due_date": "2.3.2023",
                "total_amount": 200000,
                "paid_amount": 100000,
                "receivable_amount": 100000,
                "remark": ""
            },
            {
                "name": "Mr. Won",
                "invoice_no": "xxxxxxx",
                "invoice_date": "2.2.2023",
                "due_date": "5.3.2023",
                "total_amount": 50000,
                "paid_amount": 25000,
                "receivable_amount": 25000,
                "remark": ""
            },
            {
                "name": "Mr. Kwan",
                "invoice_no": "xxxxxxx",
                "invoice_date": "2.2.2023",
                "due_date": "10.3.2023",
                "total_amount": 600000,
                "paid_amount": 300000,
                "receivable_amount": 300000,
                "remark": ""
            },
        ]
    }
}

console.log(data.results)

const PayableReportByDateRange = () => {
    const handleExportClick = (fileName) => {
        const table = document.querySelector("#xlsx-export").cloneNode(true);
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName)
    };
    const formatMessage = useFormatMessage();
    const itemsPerPage = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [currentItems, setCurrentItems] = useState();
    const [startDate, setStartDate] = useState("2023-08-03");
    const [endDate, setEndDate] = useState("2023-11-03");
    const [data, setData] = useState({
        page: 1,
        limit: itemsPerPage,
        results: null,
        totalCount: null
    });
    useEffect(() => {
        let url = BACKEND_URL + `/reports/payablereport?startDate=${startDate}&endDate=${endDate}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData({
                    ...data,
                    results: response.data,
                    totalCount: response.data.data.length
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [startDate, endDate]);

    useEffect(() => {
        setCurrentItems(data.results?.data?.slice(startIndex, endIndex))
    }, [data, currentPage]);

    useEffect(() => {
        setData({ ...data, page: currentPage })
    }, [currentPage]);

    const calculateTotal = (data, key) => {
        let total = 0;

        for (const index of data) {
            total += index[key];
        }
        return total;
    }

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.payableReportByDateRange")}</h1>
                <div className="flex">
                    <input
                        required
                        name="start_date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        className="x-input mr-3"
                        type="date"
                    />
                    <input
                        required
                        name="end_date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        className="x-input mr-4"
                        type="date"
                    />
                    <button className="header-img-button" onClick={() => {
                        handleExportClick("Payable_Report_By_Date_Range.xlsx")
                    }}>
                        <img src={printIcon} />
                    </button>
                </div>

            </div>

            <div className="table-container">
                <table className="x-table" id="xlsx-export">
                    <thead>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={5}></th>
                            <th className="hidden">{formatMessage("common.from")}</th>
                            <th className="hidden">{startDate}</th>
                            <th className="hidden">{formatMessage("common.to")}</th>
                            <th className="hidden">{endDate}</th>
                        </tr>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={9}></th>
                        </tr>
                        <tr>
                            <th>{formatMessage("common.no")}</th>
                            <th>{formatMessage("common.name")}</th>
                            <th>{formatMessage("common.invoiceNo")}</th>
                            <th>{formatMessage("common.invoiceDate")}</th>
                            <th>{formatMessage("common.dueDate")}</th>
                            <th>{formatMessage("common.totalAmount")}</th>
                            <th>{formatMessage("common.paidAmount")}</th>
                            <th>{formatMessage("common.payableAmount")}</th>
                            <th>{formatMessage("common.remark")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((item, i) => {
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{i + 1}</td>
                                    <td>{item["Name"]}</td>
                                    <td>{item["Invoice No"]}</td>
                                    <td>{item["Invoice Date"]}</td>
                                    <td>{item["Due Date"]}</td>
                                    <td>{item["Total Amount"]}</td>
                                    <td>{item["Paid Amount"]}</td>
                                    <td>{item["Payable Amount"]}</td>
                                    <td>{item["Remark"]}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{formatMessage("common.totalAmount")}</td>
                            <td>{currentItems && calculateTotal(currentItems, "Total Amount")}</td>
                            <td>{currentItems && calculateTotal(currentItems, "Paid Amount")}</td>
                            <td>{currentItems && calculateTotal(currentItems, "Payable Amount")}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="pagination-container">
                <Pagination data={data} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
}

export default PayableReportByDateRange;
