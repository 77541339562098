import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency } from "../../utils/common";
import { useFetchData } from "../../hooks/useFetchData";
import { Pagination } from "../../components/Pagination";
import { formatDateToDDMMYY, formatDateToYYYYMMDD } from "../../utils/common";
import * as XLSX from 'xlsx';

const BalanceSheet = () => {
    const handleExportClick = (fileName) => {
        const table = document.querySelector("#xlsx-export").cloneNode(true);
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName)
    };

    const formatMessage = useFormatMessage();
    const today = new Date();
    const [data, setData] = useState([]);
    const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));


    useEffect(() => {
        let url = BACKEND_URL + `/reports/balancesheet?endDate=${endDate}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData(response.data.data)
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [endDate]);

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.balanceSheet")}</h1>
                <div className="flex">
                    <input
                        required
                        name="end_date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        className="x-input mr-4"
                        type="date"
                    />
                  
                  <button className="header-img-button" onClick={() => {
                    handleExportClick("Balance_Sheet_Report.xlsx")
                         }}>
                    <img src={printIcon} />
                </button>
                </div>
                

            </div>
            <div className="table-container">
                <table className="x-table" id="xlsx-export">
                    <thead>
                        <tr>
                            <th>As of</th>
                            <th>{moment(endDate).format('MM/DD/YYYY') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, i) => {
                            return (
                                <tr key={i}
                                    // className="hover-effect" 
                                    style={{ backgroundColor: (item["Bold"] === "Yes" && item["Balance"]) ? "#F5F5F5" : null }}>
                                    <td style={{ fontWeight: item["Bold"] === "Yes" ? 'bolder' : null }}>{item["Title"]}</td>
                                    <td style={{ fontWeight: item["Bold"] === "Yes" ? 'bolder' : null }}>{item["Balance"] ? parseInt(item["Balance"]).toLocaleString('en-US') : null}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                {/* <Pagination data={data} setCurrentPage={setCurrentPage} /> */}
            </div>
        </div>
    );
}

export default BalanceSheet;




