import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatCurrency } from "../../utils/common";
import { useFetchData } from "../../hooks/useFetchData";
import { Pagination } from "../../components/Pagination";
import * as XLSX from 'xlsx';

const MerchantDataReport = () => {
    // const handleExportClick = (fileName) => {
    //     const table = document.querySelector("#xlsx-export").cloneNode(true);
    //     const workbook = XLSX.utils.table_to_book(table, { display: false });
    //     XLSX.writeFile(workbook, fileName)
    // };

    const formatMessage = useFormatMessage();

    const itemsPerPage = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [currentItems, setCurrentItems] = useState();
    const [data, setData] = useState({
        page: 1,
        limit: itemsPerPage,
        results: null,
        totalCount: null
    });

    useEffect(() => {
        let url = BACKEND_URL + "/reports/merchantreport";
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData({
                    ...data,
                    results: response.data,
                    totalCount: response.data.data.length
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, []);

    useEffect(() => {
        setCurrentItems(data.results?.data?.slice(startIndex, endIndex))
    }, [data, currentPage])

    useEffect(() => {
        setData({ ...data, page: currentPage })
    }, [currentPage])

    const handleExportClick = (fileName) => {
        const table = document.createElement('table');
        
        const headers = [
            formatMessage("common.no"),
            formatMessage("common.id"),
            formatMessage("common.merchantName"),
            formatMessage("common.balance"),
            formatMessage("common.phone")
        ];
        const thead = document.createElement('thead');
       
        const headerRow = document.createElement('tr');
        headers.forEach(header => {
            const th = document.createElement('th');
            th.innerText = header;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        table.appendChild(thead);
        
        // Create the body rows using the entire dataset
        const tbody = document.createElement('tbody');
        data.results.data.forEach((item, index) => {
            const row = document.createElement('tr');
            const numberCell = document.createElement('td');
            numberCell.innerText = index + 1;
            row.appendChild(numberCell);
    
            const transactionDateCell = document.createElement('td');
            transactionDateCell.innerText = item["ID"]
            row.appendChild(transactionDateCell);
    
            const salesIncomeCell = document.createElement('td');
            salesIncomeCell.innerText = item["Merchant Name"]
            row.appendChild(salesIncomeCell);
    
            const otherIncomeCell = document.createElement('td');
            otherIncomeCell.innerText = item["Balance"]
            row.appendChild(otherIncomeCell);
    
            const totalAmountCell = document.createElement('td');
            totalAmountCell.innerText = item["Phone 1"]
            row.appendChild(totalAmountCell);
    
            tbody.appendChild(row);
        });
    
        table.appendChild(tbody);
    
        // Create and download the Excel file
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName);
    };
    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.merchantDataReport")}</h1>
                <button className="header-img-button" onClick={() => {
                    handleExportClick("Merchant_Data_Report.xlsx")
                }}>
                    <img src={printIcon} />
                </button>

            </div>
            <div className="table-container">
                <table className="x-table" id="xlsx-export">
                    <thead>
                        <tr>
                            <th>{formatMessage("common.no")}</th>
                            <th>{formatMessage("common.id")}</th>
                            <th>{formatMessage("common.merchantName")}</th>
                            <th>{formatMessage("common.balance")}</th>
                            <th>{formatMessage("common.phone")}</th>
                            {/* <th>{formatMessage("common.phoneN", 2)}</th>
                            <th>{formatMessage("common.phoneN", 3)}</th>
                            <th>{formatMessage("common.phoneN", 4)}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((item, i) => {
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{i + 1}</td>
                                    <td>{item["ID"]}</td>
                                    <td>{item["Merchant Name"]}</td>
                                    <td>{item["Balance"]}</td>
                                    <td>{item["Phone 1"]}</td>
                                    {/* <td>{item["Phone 2"]}</td>
                                    <td>{item["Phone 3"]}</td>
                                    <td>{item["Phone 4"]}</td> */}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                <Pagination data={data} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
}

export default MerchantDataReport;




