import React, { useRef, useState } from 'react'
import app_logo from '../img/app_logo.png'
import sponsor_logo from '../img/edka-sponsor.png'
import '../css/login.css'
import { FormattedMessage } from 'react-intl'
import { useAuth } from '../context/AuthProvider';
import axios from 'axios';
import { BACKEND_URL } from '../utils/constant';
import { useForm } from '../hooks/useForm';

function Login() {
	const {switchLang, lang, setUser, checkUser, setLoading} = useAuth() ;
	const {handleInputChange, formData, error, setError, submitForm} = useForm();

	const onChangeLang = (e) => {
		switchLang(e.target.id)
	}

	const onsubmit = (e) => {
		e.preventDefault()
		const options = {
			method: 'POST',
			endpoint: '/auth/login'
		}
		setLoading(true)
		submitForm('POST', '/auth/login', formData, response => {
			const token = 'Bearer ' + response.data.data.token
			axios.defaults.headers.common['Authorization'] = token
			localStorage.setItem('user', JSON.stringify({token}));
			checkUser()
		})
	}

	return (
		
		<section className='page'>
			<div className='login-section'>
				<div className='container'>

					<div className='left-side'>
						<img className='logo' src={app_logo} />
					</div>

					<div className='divider'></div>

					<form className='right-side' onSubmit={onsubmit}>
						<h1>
							<FormattedMessage id='login.login'/>
						</h1>
						<div className='input-item'>
							<label className='input-required' htmlFor='username'>
								<FormattedMessage id='login.username'/>
							</label>
							<input 
							required
							id='username' 
							type='text' 
							name='username'
							onChange={handleInputChange}/>
						</div>
						<div className='input-item' style={{marginTop: '20px'}}>
							<label htmlFor='password'  className='input-required'>
								<FormattedMessage id='login.password'/>
							</label>
							<input 
							required
							id='password' 
							type='password' 
							name='password'
							onChange={handleInputChange}/>
						</div>

						<div className='radio-item'> 
							<input id='eng' name='lang' checked={lang === 'en'} type='radio' onChange={onChangeLang}/>
							<label htmlFor='eng'>
								<FormattedMessage id='login.eng'/>
							</label>
							<input id='myan' name='lang' checked={lang === 'my'} type='radio' onChange={onChangeLang} />
							<label htmlFor='myan'>
								<FormattedMessage id='login.myan'/>
							</label>
						</div>
						<div className='w-full'>
							<p className='text-red-400 p-0 mt-2'>{error && 'Incorrect username or password!'}</p>
						</div>
						<div className='button-container'>
							<button type='submit' className='login-btn' >
								<FormattedMessage id='login.login'/>
							</button>
						</div>

					</form>

				</div>


				<img className='sponsor-logo invisible' src={sponsor_logo}/>

			</div>

		</section>
	)
}

export default Login