import React from "react";
import useGetList from "../hooks/useGetList";

function DefaultDropdown({ entity, extQuery, id, title, setData, ...rest }) {
  // console.log(entity)
  const list = useGetList({ entity, extQuery, setData });
  return (
    <div className="dd-container">
      <select type="text" value="" className="x-input dropdown" {...rest}>
        <option value="">Select</option>
        {list.map((item, index) => (
          <option key={index} value={item[id]}>
            {item[title]}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DefaultDropdown;
