import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import DefaultDropdown from "../../components/DefaultDropdown";
import PasswordInput from "../../components/PasswordInput";

function CreateOfficeUser() {
    const { handleInputChange, formData, submitForm, error } = useForm({
        username: '',
        password: ''
    });
    const formatMessage = useFormatMessage();
    const history = useHistory();
    const { user, setLoading } = useAuth();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            ...formData,
            status: 'ACTIVE'
        };
        submitForm("POST", "/user", data, () => {
            history.push("/dashboard/officeUser");
        });
    };

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/officeUser">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>
            <div className="container">
                <h1 className="sub-page-title">
                    {formatMessage("common.createOfficeUser")}
                </h1>
                <div className="form-container">
                    <form
                    autoComplete="off"
                        onSubmit={onSubmit}
                        className="form flex flex-col justify-between w-full"
                    >
                        <div
                            className="grid grid-cols-2 gap-9"
                            style={{ maxWidth: "995px" }}
                        >
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.userRole")}
                                </label>
                                <DefaultDropdown
                                    onChange={handleInputChange}
                                    value={formData.role_id}
                                    required
                                    entity="user/role"
                                    id="role_id"
                                    title="role_name"
                                    name="role_id"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.name")}
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="name"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.username")}
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="username"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label input-required">
                                    {formatMessage("common.password")}
                                </label>
                                <PasswordInput
                                    autoComplete="new-password"
                                    
                                    name="password"
                                    required
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.phone")}
                                </label>
                                <input
                                    type="number"
                                    name="user_phone1"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                            <div className="grow">
                                <label className="x-label">
                                    {formatMessage("common.remark")}
                                </label>
                                <input
                                    type="text"
                                    name="remark"
                                    onChange={handleInputChange}
                                    className="x-input"
                                />
                            </div>
                        </div>

                        <div>
                            {error ? (
                                <span className="w-full block text-lg mb-3 text-red-500 text-right">
                                    {error}
                                </span>
                            ) : (
                                ""
                            )}
                            <div className="flex justify-end gap-5">
                                <Link
                                    to={`/dashboard/officeUser`}
                                    className="white-btn"
                                >
                                    {formatMessage("common.cancel")}
                                </Link>
                                <button className="black-btn">
                                    {formatMessage("common.create")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateOfficeUser;
