import axios from "axios";
import { useEffect, useState } from "react";

export const useFetchData = ({
  url,
  defaultQuery = [],
  sort = "-created_time",
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [query, updateQuery] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [data, setdata] = useState({
    results: [],
    page: 1,
    limit: 10,
    totalCount: 0,
    query: {},
    sort: sort,
    reloadTrigger: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let newQuery = Object.fromEntries(
          Object.entries(query).filter(([key, value]) => value !== null)
        );

        let queryString = Object.entries(newQuery)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");

        if (defaultQuery) {
          queryString = queryString + "&" + defaultQuery;
        }
        const response = await axios.get(
          `${url}?page=${page}&limit=10&sort=${sort}&included=true&${queryString}`,
          {
            withCredentials: true,
          }
        );
        setdata({
          ...data,
          results: response.data.data,
          totalCount: response.data.totalCount,
          page: page,
        });
        setTotalCount(response.data.totalCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [url, defaultQuery, sort, page, query, refetch]);

  const setCurrentPage = (newPage) => {
    const totalPage = Math.ceil(totalCount / 10);
    setPage(newPage < 1 ? 1 : newPage > totalPage ? totalPage : newPage);
  };

  const setQuery = (newQuery) => {
    updateQuery(newQuery);
    setPage(1);
  };

  const reload = () => {
    setRefetch((state) => !state);
  };

  return {
    data,
    totalCount,
    page,
    setCurrentPage,
    setQuery,
    reload,
  };
};
