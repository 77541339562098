import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatDateToDDMMYY } from "../../utils/common";
import { Pagination } from "../../components/Pagination";
import * as XLSX from 'xlsx';
import DefaultDropdown from "../../components/DefaultDropdown";
import { formatDateToYYYYMMDD } from "../../utils/common";

const CoaTransactionReport = () => {
    // const handleExportClick = (fileName) => {
    //     const table = document.querySelector("#xlsx-export").cloneNode(true);
    //     const workbook = XLSX.utils.table_to_book(table, { display: false });
    //     XLSX.writeFile(workbook, fileName)
    // };


    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const formatMessage = useFormatMessage();
    const itemsPerPage = 30;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [currentItems, setCurrentItems] = useState();
    const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(oneMonthAgo));
    const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));
    const [accountId, setAccountId] = useState(null);
    const [subAccountCategory, setSubAccountCategory] = useState();
    const [accountName, setAccountName] = useState('');

    const [data, setData] = useState({
        page: 1,
        limit: itemsPerPage,
        results: null,
        totalCount: null
    });



    useEffect(() => {
        let url = BACKEND_URL + `/reports/coatransactionreport?startDate=${startDate}&endDate=${endDate}&accountId=${accountId}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData({
                    ...data,
                    results: response.data,
                    totalCount: response.data.data.length
                });
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [accountId, endDate , startDate]);

    useEffect(() => {
        setCurrentItems(data.results?.data?.slice(startIndex, endIndex))
    }, [data, currentPage]);

    useEffect(() => {
        setData({ ...data, page: currentPage })
    }, [currentPage]);

    const handleExportClick = (fileName) => {
        const table = document.createElement('table');
        const extraInfo = [
            "", formatMessage("common.from"), startDate, formatMessage("common.to"), endDate
        ];
        const headers = [
            formatMessage("common.no"),
            formatMessage("common.transactionDate"),
            formatMessage("common.subAccountName"),
            formatMessage("common.remark"),
            formatMessage("common.credit"),
            formatMessage("common.debit"),
            formatMessage("common.remainingAmount")
        ];
        const thead = document.createElement('thead');
        const extraHeaderRow = document.createElement('tr');
        extraInfo.forEach(header => {
            const th = document.createElement('th');
            th.innerText = header;
            extraHeaderRow.appendChild(th);
        });
        thead.appendChild(extraHeaderRow);
    
        const headerRow = document.createElement('tr');
        headers.forEach(header => {
            const th = document.createElement('th');
            th.innerText = header;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        table.appendChild(thead);
    
        // Create the body rows using the entire dataset
        const tbody = document.createElement('tbody');
        data.results?.data?.forEach((item, index) => {
            const row = document.createElement('tr');
    
            // Append each cell to the row
            const numberCell = document.createElement('td');
            numberCell.innerText = index + 1;
            row.appendChild(numberCell);
    
            const transactionDateCell = document.createElement('td');
            transactionDateCell.innerText = formatDateToDDMMYY(new Date(item["TransactionDate"]));
            row.appendChild(transactionDateCell);
    
            const subAccountNameCell = document.createElement('td');
            subAccountNameCell.innerText = item["AccountName"];
            row.appendChild(subAccountNameCell);
    
            const remarkCell = document.createElement('td');
            remarkCell.innerText = item["Remark"];
            row.appendChild(remarkCell);
    
            const creditCell = document.createElement('td');
            creditCell.innerText = item["Credit"];
            row.appendChild(creditCell);
    
            const debitCell = document.createElement('td');
            debitCell.innerText = item["Debit"];
            row.appendChild(debitCell);
    
            // Hidden remaining amount logic for the first and last rows
            if (index === 0) {
                const remainingCell = document.createElement('td');
                remainingCell.innerText = item["BeforeRemainingAmount"] ?? 0;
                remainingCell.classList.add('hidden');
                row.appendChild(remainingCell);
            }
    
            if (data.results.data.length - 1 === index) {
                const remainingCell = document.createElement('td');
                remainingCell.innerText = item["AfterRemainingAmount"] ?? 0;
                remainingCell.classList.add('hidden');
                row.appendChild(remainingCell);
            }
    
            tbody.appendChild(row);
        });
    
        table.appendChild(tbody);
    
        // Create and download the Excel file
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName);
    };
    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div class="grid grid-cols-10 gap-4">
                <div className="col-span-3">
                    <h1 className="text-3xl">{formatMessage("common.coaTransactionReport")}</h1>
                </div>

                <div className="col-span-2">
                    <DefaultDropdown
                        entity="finance/subAccount"
                        value={accountId}
                        name="sub_account_id"
                        onChange={(e) => {
                            let accountId = e.target.value;
                            setAccountId(accountId);
                            let temp = subAccountCategory.filter((d) => d.lock_sub_account_id == accountId || d.sub_account_id == accountId);
                            setAccountName(temp[0].lock_sub_account_name ? temp[0].lock_sub_account_name : temp[0].sub_account_name)

                        }}
                        setData={setSubAccountCategory}
                        title="sub_account_name"
                        id="sub_account_id"
                        style={{ paddingTop: '12px' }}
                    />
                </div>
                <div className="col-span-2">
                    <input
                        required
                        name="start_date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        className="x-input mr-2 ml-2"
                        type="date"

                    />

                </div>
                <div className="col-span-2">
                    <input
                        required
                        name="end_date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        className="x-input mr-2 ml-2"
                        type="date"

                    />
                </div>
                  <button className="col-span-1 header-img-button" onClick={() => {
                        handleExportClick(`COA_Report_from_${endDate}.xlsx`)
                    }}>
                        <img src={printIcon} />
                    </button>
            </div>
            <div className="table-container">
                <table className="x-table" id="xlsx-export">
                    <thead>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={2}></th>
                            <th className="hidden">{formatMessage("common.subAccountName")}</th>
                            <th className="hidden">{accountName}</th>
                            <th className="hidden">{formatMessage("common.date")}</th>
                            <th className="hidden">{endDate}</th>
                        </tr>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={9}></th>
                        </tr>
                        <tr>
                            <th>{formatMessage("common.no")}</th>
                            <th>{formatMessage("common.transactionDate")}</th>
                            <th>{formatMessage("common.subAccountName")}</th>
                            <th>{formatMessage("common.remark")}</th>
                            <th>{formatMessage("common.credit")}</th>
                            <th>{formatMessage("common.debit")}</th>
                            <th className="hidden">{formatMessage("common.remainingAmount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((item, i) => {
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{i + 1}</td>
                                    <td>{formatDateToDDMMYY(new Date(item["TransactionDate"]))}</td>
                                    <td>{item["AccountName"]}</td>
                                    <td>{item["Remark"]}</td>
                                    <td>{item["Credit"]}</td>
                                    <td>{item["Debit"]}</td>
                                    {i === 0 && <td className="hidden">{currentItems[0].BeforeRemainingAmount ?? 0}</td>}
                                    {currentItems.length - 1 === i && <td className="hidden">{currentItems[0].AfterRemainingAmount ?? 0}</td>}

                                </tr>
                            );
                        })}


                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                <Pagination data={data} setCurrentPage={setCurrentPage} />
            </div>
        </div>
    );
}

export default CoaTransactionReport;
