import axios from "axios";
import { useAuth } from "../context/AuthProvider";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { BACKEND_URL } from "../utils/constant";

const DeleteConfirmPopup = ({ id, reload, entity }) => {
    const { setPopup } = useAuth();
    const formatMessage = useFormatMessage();
    const onConfirmClick = (e) => {
        e.preventDefault();
        axios
            .delete(BACKEND_URL + `/${entity}/` + id, { withCredentials: true })
            .then((response) => {
                if (response.status === 200) {
                    setPopup(null);
                    reload();
                }
            })
            .catch((e) => {
                console.log(e.response);
            });
    };

    const onCloseClick = (e) => {
        e.preventDefault();
        setPopup(null);
    };

    return (
        <div className="popup">
            <h4 className="text-center">
                {formatMessage("common.messageConfirmation")}
            </h4>
            <p>{formatMessage("common.messageConfirmationDes")}</p>
            <div className="flex justify-between">
                <button onClick={onConfirmClick} className="black-btn">
                    {formatMessage("common.proceed")}
                </button>
                <button onClick={onCloseClick} className="white-btn">
                    {formatMessage("common.cancel2")}
                </button>
            </div>
        </div>
    );
};



export default DeleteConfirmPopup

