import React, { useEffect, useState } from "react";
import "../css/common.css";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../hooks/useFetchData";
import { BACKEND_URL, GeneralStatus } from "../utils/constant";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { useAuth } from "../context/AuthProvider";

export default function TopTenMerchant() {
    const history = useHistory();
    const { setPopup } = useAuth();
    const currentPath = history.location.pathname;
    const { data, setCurrentPage, setQuery, reload } = useFetchData({
        url: BACKEND_URL + "/merchant",
        defaultQuery: "",
    });
    const formatMessage = useFormatMessage();

    return (
        <div className="page-layout">
            <div className="page-header">
                <h1>{formatMessage("common.topTenMerchantList")}</h1>
            </div>

            <div className="table-container">
                <table className="x-table">
                    <thead>
                        <tr>
                            <th>{formatMessage("common.id")}</th>
                            <th>{formatMessage("common.merchantName")}</th>
                            <th>{formatMessage("common.phoneN", 1)}</th>
                            <th>{formatMessage("common.balance")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.results.map((item, i) => {
                            const index = (data.page - 1) * data.limit + i + 1;
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{item.merchant_number}</td>
                                    <td>{item.merchant_name}</td>
                                    <td>{item.merchant_phone1}</td>
                                    <td>{item.balance}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
